/**
 * Defines Different Eye Conmditions
 */
export const EyeConditions = [
  'Blurred Vision',
  'Sandy / Gritty Feeling',
  'Tired Eyes',
  'Distorted Vision / Halos',
  'Itching',
  'Lazy Eye (Crossed Eye)',
  'Loss of Side Vision',
  'Burning',
  'Dry or Irritated Eyes',
  'Double Vision',
  'Foreign Body Sensation',
  'Post-Concussion Visual Symptoms',
  'Dryness',
  'Glare / Light Sensitivity',
  'Headaches',
  'Mucous Discharge',
  'Sties',
  'Migraines',
  'Redness',
  'Flashes/Floaters in Vision',
  'Seizures',
]

/**
 * Categorises Eye conditions base on their med backgrounds
 */
export const MedBackground = [
  {
    category: 'Visual Disturbances',
    conditions: [
      'Blurred Vision',
      'Distorted Vision / Halos',
      'Double Vision',
      'Flashers / Floaters in Vision',
      'Loss of Side Vision',
      'Lazy Eye (Crossed Eye)',
    ],
    toolTips: [
      `When you have blurred vision, things around you don't look as clear as they should. It can feel like you're looking through a foggy or dirty window, making it hard to see details. This can happen when you're trying to look at something far away, up close, or both.`,
      `Distorted vision means that straight lines might look wavy or curved, or objects might seem stretched or bent. Halos are bright circles or rings you might see around lights, especially at night. This can make driving at night or being in bright areas uncomfortable.`,
      `Double vision is when you see two of everything. Instead of seeing one object, your eyes show you two images of the same thing, either side-by-side or one on top of the other. It can make it hard to focus or read.`,
      `Floaters look like tiny spots, strings, or cobwebs that drift across your vision. Flashers are bright bursts or flickers of light, kind of like seeing lightning, even when your eyes are closed. Both can be normal, but sometimes they need to be checked out if they suddenly get worse.`,
      `This is when you can't see as well out of the sides of your eyes. Normally, you should be able to see things around you without turning your head. If this starts to shrink, it's like looking through a tunnel. Losing side vision can make it harder to avoid things around you.`,
      `Lazy eye, also called crossed eye or "amblyopia," happens when one eye doesn't see as well as the other, or one eye drifts inward, outward, or upward. It's usually noticed when someone is young, but can affect older kids and adults if untreated. It can make it hard to see clearly with both eyes working together.`,
    ],
  },
  {
    category: 'Eye Conditions',
    conditions: [
      'Mucous Discharge',
      'Sties',
      'Redness',
      'Sandy / Gritty Feeling',
      'Tired Eyes',
      'Itching',
    ], toolTips: [
      `Mucous discharge is when your eyes produce a sticky or gooey substance, often making your eyelids stick together, especially when you wake up. It can be a sign of an eye infection or irritation, and it may make your eyes feel uncomfortable.`,
      `A stye is a red, painful bump that forms on the edge of your eyelid, usually where your eyelashes grow. It's caused by a small infection in a gland. It can feel sore, swollen, and might make your eye feel tender or irritated.`,
      `Redness in the eyes can happen when the tiny blood vessels on the surface of the eye get irritated or swollen. It can make the white part of your eye look pink or red. This could be from allergies, infection, or just being really tired.`,
      `This is when your eyes feel like they have sand or tiny particles in them, even though nothing is there. Your eyes might feel dry, irritated, or scratchy. This feeling is common when your eyes aren't getting enough moisture.`,
      `Tired eyes, or eye strain, happen when your eyes feel fatigued after focusing for a long time, like after reading, using the computer, or driving. Your eyes may feel heavy, sore, or watery, and you might have trouble focusing.`,
      `Itchy eyes feel like they need to be rubbed all the time. This could be due to allergies, dryness, or irritation. While rubbing can feel good at first, it often makes the itching worse.`,
    ]
  },
  {
    category: 'Eye Disconfort',
    conditions: [
      'Burning',
      'Dry or Irritated Eyes',
      'Foreign Body Sensation',
      'Dryness',
      'Glare / Light Sensitivity',
    ], toolTips: [
      `Burning eyes feel hot or like they're stinging. It can happen when your eyes are dry, irritated, or exposed to things like smoke, pollution, or even wind. It can be uncomfortable, but usually clears up once the irritation goes away.`,
      `Dry eyes occur when your eyes don't make enough tears or the tears don't work well. They can feel sore, itchy, or like something is stuck in them. This can be made worse by reading, screen time, or being in air-conditioned or windy places.`,
      `This is the feeling that something is stuck in your eye, like a grain of sand or an eyelash, even when there's nothing actually there. It can make your eyes water and feel irritated until the sensation goes away.`,
      `Dryness is when your eyes don't feel properly hydrated. You might notice your eyes feeling rough, itchy, or sore. Sometimes your eyes might even water a lot in response, but the tears don't give enough relief.`,
      `Glare is when bright lights make it hard to see clearly, causing discomfort, especially in the sun or when facing headlights at night. Light sensitivity, or "photophobia," is when your eyes hurt or feel uncomfortable in bright light.`,
    ]
  },
 
]

/**
 * List of Basic Survey Questions For Vision Symptomps
 */
export const VisionSymptomsSurvey = [
  'Do your eyes feel tired when reading or doing close work?',
  'Do your eyes feel uncomfortable when reading or doing close work?',
  'Do you have headaches when reading or doing close work?',
  'Do you feel sleepy when reading or doing close work?',
  'Do you lose concentration when reading or doing close work?',
  'Do you have trouble remembering What you read?',
  'Do you have double vision when reading or doing close work?',
  'Do you see the words move, jump, swim or appear to float on the page when reading or doing close work?',
  'Do you feel like you read slowly?',
  'Do your eyes ever hurt when reading or doing close work?',
  'Do your eyes feel sore when reading or doing close work?',
  'Do you feel pulling feeling around your eyes when reading or doing close work?',
  'Do you notice the words blurring or coming in and out of focus when reading or doing close work?',
  'Do you lose your place while reading or doing close work?',
  'Do you have to reread the same line of words when reading?',
]

/**
 * Lists out Tools Used in the System and Their Functions
 */

export const Library = [
  {
    title: 'Handheld Visual Performance',
    description:
      'Measures your vision when using handheld devices and reading a book.',
  },
  {
    title: 'Workstation Visual Performance',
    description:
      'Measures your vision at the screen the test is being performed.',
  },
  {
    title: 'Visual Symptom Score',
    description:
      'This survey analyzes certain symptoms that can impact the quality of your vision.',
  },
  {
    title: 'Visual Distortion Grid',
    description: 'This grid helps detect spots of vision loss or distortion.',
  },
  {
    title: 'Blind Spot Checker (Optional)',
    description: 'This checks for blind spots in your visual field.',
  },
  {
    title: 'Shadow Detection (Optional)',
    description: 'Measures your contrast sensitivity.',
  },
  {
    title: 'Do I have dry eyes? (Optional)',
    description:
      "Helps determine if you're experiencing a condition known as dry eye.",
  },
]

/**
 * Different Properties of Glass
 */
export const GlassProps = ['sph', 'cyl', 'axis', 'prism', 'base', 'pd']


// Styling constants
export const headerTextSX = { 
  "2xl": {fontSize: "50px", color: "#7C6FF7", fontWeight: 800}, 
  "2xlNarrow" : {fontSize: "40px", color: "#7C6FF7", fontWeight: 800}, 
  "xl": {fontSize: "40px", color: "#7C6FF7", fontWeight: 800}, 
  "lg" :{fontSize: "30px", color: "#7C6FF7", fontWeight: 800}, 
  "md" : {fontSize:  "25px", color: "#7C6FF7", fontWeight: 800} 
};

export const headerTextIgnoreNarrowSX = { 
  "2xl": {fontSize: "50px", color: "#7C6FF7", fontWeight: 800}, 
  "2xlNarrow" : {fontSize: "30px", color: "#7C6FF7", fontWeight: 800},
  "xl": {fontSize: "40px", color: "#7C6FF7", fontWeight: 800}, 
  "lg" :{fontSize: "30px", color: "#7C6FF7", fontWeight: 800}, 
  "md" : {fontSize:  "25px", color: "#7C6FF7", fontWeight: 800} 
};

export const bodyTextSX = { 
  "2xl": {fontSize: "30px", color: "#000000", fontWeight: 400, lineHeight: "32px"},
  "2xlNarrow" : {fontSize: "25px", color: "#000000", fontWeight: 400, lineHeight: "27px"}, 
  "xl": {fontSize: "25px", color: "#000000", fontWeight: 400, lineHeight: "27px"}, 
  "lg" :{fontSize: "22px", color: "#000000", fontWeight: 400, lineHeight: "24px"}, 
  "md" : {fontSize:  "20px", color: "#000000", fontWeight: 400, lineHeight: "22px"} 
};

export const bodyTextIgnoreNarrowSX = { 
  "2xl": {fontSize: "30px", color: "#000000", fontWeight: 400, lineHeight: "32px"},
  "2xlNarrow" : {fontSize: "22px", color: "#000000", fontWeight: 400, lineHeight: "24px"}, 
  "xl": {fontSize: "25px", color: "#000000", fontWeight: 400, lineHeight: "27px"}, 
  "lg" :{fontSize: "22px", color: "#000000", fontWeight: 400, lineHeight: "24px"}, 
  "md" : {fontSize:  "20px", color: "#000000", fontWeight: 400, lineHeight: "22px"} 
};

export const largeBodyTextSX = { 
  "2xl": {fontSize: "30px", color: "#000000", fontWeight: 400, lineHeight: "32px"}, 
  "2xlNarrow" : {fontSize: "28px", color: "#000000", fontWeight: 400, lineHeight: "27px"}, 
  "xl": {fontSize: "28px", color: "#000000", fontWeight: 400, lineHeight: "27px"}, 
  "lg" :{fontSize: "26px", color: "#000000", fontWeight: 400, lineHeight: "24px"}, 
  "md" : {fontSize:  "24px", color: "#000000", fontWeight: 400, lineHeight: "22px"} 
};

export const smallBodyTextSX = { 
  "2xl": {fontSize: "18px", color: "#000000", fontWeight: 400, lineHeight: "20px"}, 
  "2xlNarrow" : {fontSize: "16px", color: "#000000", fontWeight: 400, lineHeight: "18px"}, 
  "xl": {fontSize: "16px", color: "#000000", fontWeight: 400, lineHeight: "18px"}, 
  "lg" :{fontSize: "14px", color: "#000000", fontWeight: 400, lineHeight: "16px"}, 
  "md" : {fontSize:  "12px", color: "#000000", fontWeight: 400, lineHeight: "14px"} 
};

export const buttonSX = { 
  "2xl": { fontSize: "24px", height: "56px", fontWeight: "700" }, 
  "2xlNarrow" : { fontSize: "22px", height: "50px", fontWeight: "700" }, 
  "xl":  { fontSize: "22px", height: "50px", fontWeight: "700" }, 
  "lg" : { fontSize: "20px", height: "45px", fontWeight: "700" }, 
  "md" : { fontSize: "18px", height: "40px", fontWeight: "700" }
};