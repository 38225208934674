import { getFunctions, httpsCallable } from "firebase/functions";
import {functions} from "../Firebase";

export const addOrUpdatePatientResults = async (patientEmail, patientInfo, assessmentResult, attachmentContent) => {
    
    const checkOrUpdatePatient = httpsCallable(functions, 'checkOrUpdatePatient');
    try {
        const result = await checkOrUpdatePatient({ patientEmail: patientEmail, patientInfo: patientInfo, assessmentResult: assessmentResult, attachmentContent: attachmentContent  });
        return result.data;

    } catch (error) {
        console.log(error);
        throw "Sending data to your clinician failed, click to retry."

    }
}

export const getClinicianEmail = async (clinicianCode) => {
    
    const checkClinicianCode = httpsCallable(functions, 'getClinicianEmail');
    try {
        const result = await checkClinicianCode({ clinicianCode: clinicianCode  });
        console.log(result);
        return result.data;

    } catch (error) {
        console.log(error);
        throw error.message;

    }
}