import React from "react";
import covereye from "../Images/cover_eye.png";
import { Button, Stack, Grid } from "@mui/material";
import MainLayout from "../components/layout/MainLayout";
import TypographyTitle from "../components/TypographyTitle";
import ButtonNavigation from "../components/ButtonNavigation";

function CoverRightEye({ handleVisualAcuity}) {

  return (
    <MainLayout 
      headerContent={
        <TypographyTitle textAlign="center">
            Maintain your distance to the screen and cover your <br /> RIGHT
            eye.
        </TypographyTitle>
      }
      bodyContent={
        <Stack direction justifyContent="center" alignItems="center" sx={{width: "100%", height: "100%", maxHeight: "400px"}}>
            <img
              className="h-full w-auto aspect-auto"
              src={covereye}
            />
        </Stack>
      }
      footerContent={
        <ButtonNavigation width={"170px"} onClick={handleVisualAcuity}>
          NEXT
        </ButtonNavigation>
      }
    />
  );
}

export default CoverRightEye;
