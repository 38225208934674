import React from "react";
import { LaunchGame } from "../../scripts/GameEngines";

import TestInstructionE from "../../scenes/TestInstructionE";

function Step14({ handleNext, handlePrev, setVALNCBResponse, isTest }) {
  const handleVisualAcuity = () => {
    if (isTest) {
      setVALNCBResponse({
        "Acuity": "40",
        "Warning": 40,
        "Hits": [
            5,
            0
        ]
      });
      handleNext();
    } else 
    LaunchGame(
      "VisAcuityNear",
      (results) => {
        console.log(results);
        setVALNCBResponse(results);
        handleNext();
      },
      40
    );
  };

  return (
    <TestInstructionE handleVisualAcuity={handleVisualAcuity} />
  );
}

export default Step14;
