import React from "react";
import {  Button, Checkbox, Grid, Stack, useMediaQuery } from "@mui/material";
import { LaunchDistance } from "../../scripts/GameEngines";
import placeh1 from "../../Images/placeholderImage1.png";
import placeh2 from "../../Images/placeholderImage2.png";
import Navigation from "../navigations/Navigation";
import FixedHeading from "../FixedHeading";
import useLayoutChange from "../../hooks/useLayoutChange";

function DoubleColumnInstruction({instruction1, instruction2, imageSrc1, imageSrc2 }) {

  const isNarrow = useMediaQuery("(min-height: 400px) and (max-height: 680px)");
  let maxImgHeight = "280px";
  if (isNarrow) maxImgHeight = "220px";

  return (

        <Stack direction="row" justifyContent="center" alignItems="center" sx={{width: "100%", height: "100%"}}>

          <Grid container justifyContent="center" spacing="20px" sx={{maxWidth: "1100px", height: "fit-content", pt: 0}}>
            <Grid item xs={12}>
              <Grid container spacing="12px" sx={{width: "100%", height: "100%"}}>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="center">
                    {instruction1}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" justifyContent="center">
                    {instruction2}
                  </Stack>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Grid container spacing="12px" justifyContent="center" sx={{width: "100%", height: "100%",  overflow: "hidden"}}>
                <Grid item xs={6} >
                  <Stack direction="row" justifyContent="center" sx={{height: "100%", maxHeight: maxImgHeight}}>
                    <img src={imageSrc1} className="h-full w-auto object-cover" />
                  </Stack>
                </Grid>
                <Grid item xs={6} >
                  <Stack direction="row" justifyContent="center" sx={{height: "100%", maxHeight: maxImgHeight}} >
                    <img className="h-full w-auto object-cover"  src={imageSrc2} />
                  </Stack>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

        </Stack>

  );
}

export default DoubleColumnInstruction;
