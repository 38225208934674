import { List, Typography } from "@mui/material";
import { bodyTextSX, headerTextSX } from "../utils/constants";
import useLayoutChange from "../hooks/useLayoutChange";



const ListBox = (props) => { 

    const theSize = useLayoutChange();

    return (
        <List
            {...props}
            sx={theSize && { listStyleType: 'disc', py:0,  ...bodyTextSX[theSize]}}
        >
        {props.children}
        </List>
  )};

export default ListBox;