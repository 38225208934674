import React, { useState } from "react";
import { Button } from "rsuite";
import FixedHeading from "../FixedHeading";

function DryEyeSurvey1({ handleNext, setSurveyAnswers }) {
  const initialSymptoms = [
    {
      symptom: "Dryness, Grittiness or Scratchiness",
      options: new Array(3).fill(false),
    },
    { symptom: "Soreness or Irritation", options: new Array(3).fill(false) },
    { symptom: "Burning or Watering", options: new Array(3).fill(false) },
    { symptom: "Eye Fatigue", options: new Array(3).fill(false) },
  ];

  const [symptomsFrequency1, setSymptomsFrequency1] = useState([
    ...initialSymptoms,
  ]);
  const [symptomsFrequency2, setSymptomsFrequency2] = useState([
    ...initialSymptoms,
  ]);

  const handleOnChange1 = (symptomIndex, optionIndex) => {
    setSymptomsFrequency1((prevSymptoms) =>
      prevSymptoms.map((symptom, index) =>
        index === symptomIndex
          ? {
              ...symptom,
              options: symptom.options.map((opt, i) => i === optionIndex),
            }
          : symptom
      )
    );
    console.log(symptomsFrequency1);
  };

  const handleOnChange2 = (symptomIndex, optionIndex) => {
    setSymptomsFrequency2((prevSymptoms) =>
      prevSymptoms.map((symptom, index) =>
        index === symptomIndex
          ? {
              ...symptom,
              options: symptom.options.map((opt, i) => i === optionIndex),
            }
          : symptom
      )
    );
  };
  const surveyAnswers = [...symptomsFrequency1, ...symptomsFrequency2];
  const setSurveyAnswersAns = (prevAnswers) => {
    setSurveyAnswers(() =>
      prevAnswers.map((ans, index) => {
        const selectedOptionIndex = surveyAnswers[index].options.findIndex(
          (opt) => opt
        );
        return {
          ...ans,
          answer:
            selectedOptionIndex !== -1 ? selectedOptionIndex.toString() : "-",
        };
      })
    );
    handleNext();
  };

  return (
    <div className="flex flex-col h-[10vh]  h-[80vh] items-center justify-center gap-10 mx-auto w-max-w-[90rem] w-full">
      <div className="flex  justify-between   h-full  flex-col">
        <div className="  flex flex-col items-center justify-center w-full">
          <FixedHeading>
            <p className="text-[#7C6FF7] lg:text-[30px] text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
              Dry Eye Survey2/2
            </p>
          </FixedHeading>
          <div className="flex flex-col gap-4 w-[90%] pt-6 text-[24px]">
            <table className="h-[15rem] ">
              <thead className="bg-[#dddddd]">
                <tr>
                  <th></th>
                  <th colSpan={3} className="text-center ">
                    How frequently?
                  </th>
                </tr>
                <tr>
                  <th className="px-10 text-center ">Symptom</th>
                  <th className="px-10 text-center ">Sometimes</th>
                  <th className="px-10 text-center ">Often</th>
                  <th className="px-10 text-center ">Constant</th>
                </tr>
              </thead>
              <tbody>
                {symptomsFrequency1.map(
                  ({ symptom, options }, symptomIndex) => (
                    <tr key={symptomIndex}>
                      <td className="text-center">{symptom}</td>
                      {options.map((option, optionIndex) => (
                        <td
                          key={optionIndex}
                          className="text-center radio-cell"
                        >
                          <input
                            type="radio"
                            id={`${symptom}_${optionIndex}_1`}
                            name={`${symptom}_${symptomIndex}_1`}
                            checked={option}
                            onChange={() =>
                              handleOnChange1(symptomIndex, optionIndex)
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <table className="h-[15rem]">
              <thead className="bg-[#dddddd]">
                <tr>
                  <th></th>
                  <th colSpan={3} className="px-40 text-center">
                    When?
                  </th>
                </tr>
                <tr>
                  <th className="px-10 text-center ">Symptom</th>
                  <th className="text-center ">At this time</th>
                  <th className="text-center ">Within past 72 hours</th>
                  <th className="text-center ">Within past 3 months</th>
                </tr>
              </thead>
              <tbody>
                {symptomsFrequency2.map(
                  ({ symptom, options }, symptomIndex) => (
                    <tr key={symptomIndex}>
                      <td className="text-center">{symptom}</td>
                      {options.map((option, optionIndex) => (
                        <td key={optionIndex} className="radio-cell">
                          <input
                            type="radio"
                            id={`${symptom}_${optionIndex}_2`}
                            name={`${symptom}_${symptomIndex}_2`}
                            checked={option}
                            onChange={() =>
                              handleOnChange2(symptomIndex, optionIndex)
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-center   w-full uppercase font-Montserrat">
          <Button
            style={{
              backgroundColor: "#7C6FF7",
              borderRadius: "25px",
              color: "white",
              fontSize: "24px",
              padding: "1.8rem",
              height: "3.5rem",
              fontWeight: "700",
              width: "177px",
            }}
            appearance="primary"
            className="button-style"
            onClick={() => setSurveyAnswersAns(surveyAnswers)}
          >
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DryEyeSurvey1;
