import { Button } from '@mui/material'
import React from 'react'

function Navigation({
  handlePrev,
  handleNext,
  prevText = 'Back',
  nextText = 'Next',
  middleText = '',
}) {
  return (
    <div className='button-background-double mt-10 flex gap-3 justify-center'>
      <Button
        style={{ backgroundColor: '#01216B' }}
        sx={{ fontSize: { xs: '0.7rem', md: '0.875ren' } }}
        color='primary'
        className='button-style min-w-[6.43rem] sm:min-w-[10rem] md:min-w-[11.43rem] h-[6vh]'
        onClick={handlePrev}
      >
        {prevText}
      </Button>
      {middleText && (
        <p className='text-center text-[9pt] sm:text-[10.5pt] md:text-lg'>
          {middleText}
        </p>
      )}
      <Button
        style={{ backgroundColor: '#01216B' }}
        sx={{ fontSize: { xs: '0.7rem', md: '0.875ren' } }}
        color='primary'
        className='button-style min-w-[6.43rem] sm:min-w-[10rem] md:min-w-[11.43rem] h-[6vh]'
        onClick={handleNext}
      >
        {nextText}
      </Button>
    </div>
  )
}

export default Navigation
