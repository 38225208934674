import React from "react";
import ContactForm from "../../scenes/ContactForm";
import { Button } from "@mui/material";

function Step1({ handleNext, contactFormData, setContactFormData, isTest }) {
  const isEmailEmpty = () => {
    return !(contactFormData.email.trim() === "");
  };
  const conditionalNextStep = (conditional, failureMessage) => {
    if (conditional === true) {
      handleNext();
    }
  };
  return (
    <>
      <ContactForm
        formData={contactFormData}
        setFormData={setContactFormData}
        isError={false}
        setError={null}
        handleNext={handleNext}
        isTest={isTest}
      />
    </>
  );
}

export default Step1;
