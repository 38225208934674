import React, { useState } from "react";

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import MainLayout from "../components/layout/MainLayout";
import TypographyTitle from "../components/TypographyTitle";
import TextAndImage from "../components/layout/TextAndImage";
import ButtonNavigation from "../components/ButtonNavigation";
import TypographyBody from "../components/TypographyBody";
import useLayoutChange from "../hooks/useLayoutChange";

function ContactForm({ formData, setFormData, isError, setError, handleNext, isTest }) {

  const theSize = useLayoutChange();

  let theSpacing = "50px";
  let theImageHeight = "150px";
  if (theSize == "2xl") {theSpacing = "150px"; theImageHeight="200px"}
  else if (theSize == "xl") {theSpacing = "100px"; theImageHeight="175px"}

  
  const formik = useFormik({
    initialValues: {
        email: isTest ? 'patient@sample.com' : '' ,
        postalCode: isTest ? 'OX234I' : '' ,
        submit: null,
    },
    validationSchema: yup.object({
        email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
        postalCode: yup.string().required('Postal Code is required'),
    }),
    onSubmit: async (values, {resetForm}) => {
      setFormData((prevFormData) => ({
        // ...prevFormData,
        email: values.email,
        postalCode: values.postalCode
      }));
      handleNext();
    }
    });


  return (
    <MainLayout 
      bodyContent={
        <TextAndImage spacing={theSpacing} imageSrc="./Assets/VSL_Logo.svg" imageAlt="Science Vision Labs Image" imageMaxHeight={theImageHeight} textComponent={
            <form
                  className="flex flex-col justify-center w-full max-w-[450px]"
                  onSubmit={formik.handleSubmit}
            >
            <Stack spacing="20px">
              <TypographyTitle>Getting Started</TypographyTitle>

                      <TextField
                        // style={{ border: "1px solid black", borderRadius: "5px" }}
                        error={Boolean(formik.errors.email && formik.touched.email)}
                        label={"Email"}
                        fullWidth
                        type="email"
                        name="email"
                        variant="outlined"
                        placeholder="Enter your email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        helperText={ formik.touched.email && formik.errors.email}
                        
                      />

                    <TypographyBody>
                      We need this to send the results to the doctor.
                    </TypographyBody>
                    {/* <div className="flex text-[24px] font-[400] leading-[29px] font-Montserrat items-center">
                        We need this to send the results to the doctor.
                    </div> */}


                    <TextField
                      // style={{ border: "1px solid black", borderRadius: "5px" }}
                      error={Boolean(formik.errors.postalCode && formik.touched.postalCode)}
                      helperText={ formik.touched.postalCode && formik.errors.postalCode}
                      fullWidth
                      label="Postal Code"
                      type="text"
                      name="postalCode"
                      variant="outlined"
                      placeholder="Postal Code"
                      value={formik.values.postalCode}
                      onChange={formik.handleChange}
                      
                    />



            </Stack>
            </form>
        }
         />
      }
      footerContent={
        <ButtonNavigation onClick={formik.handleSubmit}>
            Next
        </ButtonNavigation>
      }
    />
  );
}

export default ContactForm;
