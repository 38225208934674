import { Grid, Stack, Typography, useMediaQuery, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import MainLayout from "./MainLayout";
import { bodyTextSX, headerTextSX } from "../../utils/constants";
import useLayoutChange from "../../hooks/useLayoutChange";
import TypographyTitle from "../TypographyTitle";
import TypographyBody from "../TypographyBody";
import ButtonNavigation from "../ButtonNavigation";

const TextBoxAndImage = ({ imageSrc, imageAlt, boxContent, imageMaxHeight, spacing  }) => {

    const theSize = useLayoutChange();
    let boxMaxwidth = "470px";
    if (theSize == "2xl") boxMaxwidth = "700px"
    else if (theSize == "xl") boxMaxwidth = "600px"

    return (
            <Stack direction="row" alignItems="center" spacing={spacing ? spacing : 0} sx={{ height: "100%"}} >
                <Stack justifyContent="center" sx={{border: "solid 1px", px: "3rem", py: "1.2rem", borderRadius: "25px", height: "100%", width: "100%", maxWidth: boxMaxwidth}}>
                    {boxContent}
                </Stack>
                
                <Stack alignItems="center" justifyContent="center" sx={{height: "100%", overflow: "hidden", maxHeight: imageMaxHeight ? imageMaxHeight : "unset"}}>
                    <img
                        className="h-full w-auto object-cover"
                        src={imageSrc}
                        alt={imageAlt}
                    ></img>
                </Stack>
            </Stack>
  );
};

export default TextBoxAndImage;
