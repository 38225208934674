import { Typography } from "@mui/material";
import { bodyTextIgnoreNarrowSX, bodyTextSX, headerTextSX, largeBodyTextSX, smallBodyTextSX } from "../utils/constants";
import useLayoutChange from "../hooks/useLayoutChange";



const TypographyBody = (props) => { 

    const theSize = useLayoutChange();

    let contentSX = theSize ? props.isLarge ? largeBodyTextSX[theSize] : props.isSmall ? smallBodyTextSX[theSize] : props.ignoreNarrow ? bodyTextIgnoreNarrowSX[theSize] : bodyTextSX[theSize] : {};
    if (props.lineHeightPlus) contentSX = {...contentSX, lineHeight: `calc(${bodyTextSX[theSize]?.lineHeight} + ${props.lineHeightPlus})`}

    return (
        <Typography
            {...props}
             component={props.component ? props.component : "p"}
            sx={contentSX}
        >
        {props.children}
        </Typography>
  )};

export default TypographyBody;