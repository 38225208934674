import React, { useState } from "react";
import { Button, Grid, Stack } from "@mui/material";
import homevvs from "../../Images/home-vvs.png";
import Navigation from "../navigations/Navigation";
import { LaunchGame } from "../../scripts/GameEngines";
import desk from "../../Images/Distance-from-screen.png";
import girls from "../../Images/girls.png";
import covereye from "../../Images/cover_eye.png";
import visualacuity from "../../Images/visualacuity.png";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import TypographyTitle from "../TypographyTitle";
import TextBoxAndImage from "../layout/TextBoxAndImage";
import ButtonNavigation from "../ButtonNavigation";
import TypographyBody from "../TypographyBody";
import TestInstructionE from "../../scenes/TestInstructionE";

function Step16({ handleNext, handlePrev, isTest, setVALFCBResponse = () => {} }) {
  const [stepFix, setStepFix] = useState(false);
  const handleVisualAcuity = () => {
    if (isTest) {
      setVALFCBResponse({
        "Acuity": "20",
        "Hits": [
            5,
            0
        ]
      });
      handleNext();
    } else
    LaunchGame(
      "VisAcuityFar",
      (results) => {
        console.log(results);
        setVALFCBResponse(results);
        handleNext();
      },
      "calculateDistance"
    );
  };
  return (
    <>
      {!stepFix && (
        <MainLayout 
          headerContent={
            <TypographyTitle>
                Next, we’re going to measure your computer acuity.
            </TypographyTitle>
          }
          bodyContent={
            <TextBoxAndImage
              boxContent={
                <Stack spacing="40px">
                  <TypographyBody>
                        This will help determine if you
                        need glasses or other
                        corrective methods at a screen distance.
                  </TypographyBody>
                  <TypographyBody>
                  <span className="font-[700] block">
                      Please remove your glasses
                      </span>
                      after reading the instructions
                      and before starting the tests.
                  </TypographyBody>
                </Stack>
              }
              imageSrc={girls}
              spacing="20px"
            />
          }
          footerContent={
            <ButtonNavigation width="300px" onClick={() => setStepFix(true)}>
                START INSTRUCTIONS
            </ButtonNavigation>
          }
        />
      )}
      {stepFix && (
        <TestInstructionE handleVisualAcuity={handleVisualAcuity} />
      )}
    </>
  );
}

export default Step16;


// <div className="flex flex-col w-full   w-max-w-[90rem] w-full h-[10vh]  h-[80vh] mx-auto gap-[4rem] items-center justify-center">
// <div className="flex  justify-between  w-full  h-full  flex-col">
//   <div className=" flex flex-col gap-[2rem]">
//     <div className="flex justify-start w-full">
//       <FixedHeading>
//         <p className="text-[#7C6FF7] pt-2 lg:text-[30px] text-center text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
//           Test Instructions
//         </p>
//       </FixedHeading>
//     </div>
//     <div className="flex gap-[2rem] w-full">
//       <div className="flex flex-col-reverse w-1/2 gap-6">
//         <div className="flex border-[3px] mt-2 lg:mt-0 border-black rounded-[25px] justify-center">
//           <img
//             className="xl:h-[23.5rem] h-[15rem] lg:h-[20rem] aspect-auto"
//             src={covereye}
//           />
//         </div>
//         <p className="text-[1.5rem] text-center h-[3.5rem]">
//           Cover the indicated eye with your hand.
//         </p>
//       </div>
//       <div className="flex flex-col-reverse w-1/2 gap-6">
//         <div className="xl:h-[23.5rem] mt-2 lg:mt-0 h-[15rem] lg:h-[20rem] relative z-40 border-[1px] border-black flex justify-center">
//           <img className="" src={visualacuity} />
//         </div>

//         <p className="text-[1.5rem] text-center h-[3.5rem]">
//           Using your other hand, indicate the open direction of the E
//           symbols.
//         </p>
//       </div>
//     </div>
//   </div>
//   {/* <a>Click to see the instruction video.</a> */}

//   <div className="flex justify-center  relative   w-full uppercase font-Montserrat">
//     <Button
//       style={{
//         backgroundColor: "#7C6FF7",
//         borderRadius: "25px",
//         color: "white",
//         fontSize: "24px",
//         padding: "1.8rem",
//         height: "3.5rem",
//         fontWeight: "700",
//         width: "312px",
//       }}
//       appearance="primary"
//       className="button-style"
//       onClick={(event) => {
//         handleVisualAcuity();
//       }}
//     >
//       START TEST
//     </Button>
//   </div>
// </div>
// </div>