import React from "react";
import placeh3 from "../../Images/gifs/AmslerGrid.gif";
import gif from "../../Images/gifs/gif.gif";
import { LaunchGame } from "../../scripts/GameEngines";
import Navigation from "../navigations/Navigation";
import { Button, Grid, Stack } from "@mui/material";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import ButtonNavigation from "../ButtonNavigation";
import TextBoxAndImage from "../layout/TextBoxAndImage";
import TypographyTitle from "../TypographyTitle";
import ListBox from "../ListBox";
import ListBoxItem from "../ListBoxItem";

function Step19({ handleNext, handlePrev, setCBResponse }) {
  return (
    <MainLayout 
      headerContent={
        <TypographyTitle>
          Test Instructions
        </TypographyTitle>
      }
      bodyContent={
        <TextBoxAndImage 
          boxContent={
            <ListBox>
              <ListBoxItem paddingY="6px">
                With one eye covered, focus on the black dot in the center.
              </ListBoxItem>
              <ListBoxItem paddingY="6px">
                  If the grid lines appear to be wavy or irregular, or you see a
                  hole in the grid, use the mouse to circle the distorted areas
                  or draw the shape you see with the mouse.
              </ListBoxItem>
              <ListBoxItem paddingY="6px">
                If the grid lines appear normal, just click submit.
              </ListBoxItem>
            </ListBox>
          }
          imageSrc={gif}
        />
      }
      footerContent={
        <ButtonNavigation width="250px" onClick={() => LaunchGame("AmslerGrid",(data) => {setCBResponse(data); handleNext();},40)}>
            START TEST
        </ButtonNavigation>
      }
    />
  );
}

export default Step19;
