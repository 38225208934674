import React from "react";
import homevvs from "../../Images/home-vvs.png";
import { Button, Grid, Stack } from "@mui/material";
import reading from "../../Images/desk.png";
import Logo from "../logo/Logo";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import ButtonNavigation from "../ButtonNavigation";
import TypographyTitle from "../TypographyTitle";
import TextBoxAndImage from "../layout/TextBoxAndImage";
import TypographyBody from "../TypographyBody";

function Step13({ handleNext, handlePrev }) {
  return (
    <MainLayout 
      headerContent={
        <TypographyTitle>
            We’re going to measure your reading acuity.
        </TypographyTitle>
      }
      bodyContent={
        <TextBoxAndImage 
          boxContent={
            <Stack spacing="40px">
                <TypographyBody>
                  This will help determine if you
                  need things like reading glasses
                  or other corrective methods.
                </TypographyBody>{" "}
                <TypographyBody>
                  <span className="font-[700] block">
                    Please remove your glasses
                  </span>
                  after reading the instructions and before starting the tests.
                </TypographyBody>
              </Stack>
          }
          imageSrc={reading}
          imageAlt="Reading Image"
          spacing="40px"
        />
      }
      footerContent={
        <ButtonNavigation width="350px" onClick={handleNext}>
          START INSTRUCTIONS
        </ButtonNavigation>
      }
    />
  );
}

export default Step13;
