import React, {useState, useEffect} from "react";
import { Button } from "rsuite";
import { LinearProgress, Stack, Typography} from "@mui/material";

import basicinfo from "../../Images/basic-info.png";
import callibration1 from "../../Images/callibration-1.png";
import Navigation from "../navigations/Navigation";
import doctor from "../../Images/docchahcha.png";
import convertComponentToPDFBase64 from "../../scripts/pdfConverter";

// import "../../App.css";
import bg from "../../Images/bgcalib.png";
import Step24 from "./Step24";
import { addOrUpdatePatientResults } from "../../utils/firebaseFunctions";
import ListBox from "../ListBox";
import ListBoxItem from "../ListBoxItem";
import MainLayout from "../layout/MainLayout";
import TextBoxAndImage from "../layout/TextBoxAndImage";
import ButtonNavigation from "../ButtonNavigation";

function Step22({ 
  handlePrev,
  clinicianEmail,
  formDataVision,
  conditions,
  contactFormData,
  CBResponse,
  wearGlasses,
  tableDataContacts,
  tableData,
  CBResponseR,
  additionalData,
  VALNCBResponse,
  VARNCBResponseR,
  VALFCBResponse,
  VARFCBResponseR,
  distance,
  dryEye1,
  dryEye2,
 }) {
  const [isReady, setReady] = useState(false);
  const [reportView, setReportView] = useState(false);

  
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const [savedData, setSavedData] = useState(null);


  useEffect(() => {
    if (!error && !isSubmitting && !savedData)
      handleSaveResults();

    if (!clinicianEmail) setReady(true);
  }, []);

  const handleSaveResults = async () => {

    try {
      setSubmitting(true);
      setError(null);

      if (!contactFormData) throw "No Data is provided. Reload the page to start over.";
      if (!contactFormData.email || contactFormData.email == "" ) throw "No Data is provided. Reload the page to start over.";
      if (!contactFormData.postalCode || contactFormData.postalCode == "" ) throw "No Data is provided. Reload the page to start over.";

      const resultData = {
          conditions: conditions,
          CBResponse: CBResponse,
          wearGlasses: wearGlasses,
          tableDataContacts: tableDataContacts,
          tableData: tableData,
          CBResponseR: CBResponseR,
          additionalData: additionalData,
          VALNCBResponse: VALNCBResponse,
          VARNCBResponseR: VARNCBResponseR,
          VALFCBResponse: VALFCBResponse,
          VARFCBResponseR: VARFCBResponseR ,
          distance: distance,
          dryEye1: dryEye1,
          dryEye2: dryEye2
      }

      const theoutput = await convertComponentToPDFBase64();


      const payload = {
        patientEmail : contactFormData.email,
        patientInfo : { postalCode: contactFormData.postalCode, ...formDataVision },
        assessmentResult : {
            clinicianEmail: clinicianEmail,
             ...resultData 
        },
        attachmentContent: theoutput
      }

      console.log(payload);

      const result = await addOrUpdatePatientResults(payload.patientEmail, payload.patientInfo, payload.assessmentResult, payload.attachmentContent);
      setSavedData(result);
      setSubmitting(false);
      setReady(true);
    } catch (err) {
        setSubmitting(false);
        setError(err);
    }
  }


  return (
    <>
    {!reportView &&
    <div className="flex flex-col max-w-[80rem] w-full my-[10vh]    h-[80vh] gap-2 mx-auto justify-center    align-center">
      <div className="absolute h-full w-full z-[-1] inset-0">
        <img
          src={bg}
          className="object-cover w-[1550px] 2xl:w-[100%] h-[1100px] xl:h-screen"
          alt=""
        />
      </div>
      <MainLayout 
        bodyContent={
          <TextBoxAndImage 
            boxContent={
              <ListBox>
                <ListBoxItem paddingY="18px">The screener is now complete.</ListBoxItem>
                <ListBoxItem paddingY="18px">The results will be sent to your clinician for review. Also, you can download them in the next page.</ListBoxItem>
                <ListBoxItem paddingY="18px">Please contact your clinician for any updates.</ListBoxItem>
              </ListBox>
            }
            imageSrc={doctor}
            spacing="50px"
          />
        }
        footerContent={
          <Stack alignItems="center" spacing="12px">
            { clinicianEmail && !error && isSubmitting && !savedData &&
              <Stack>
                  <Typography>Sending data to your clinician...</Typography>
                  <LinearProgress />
              </Stack>
            }
            { clinicianEmail && error &&
              <Typography onClick={handleSaveResults} sx={{textDecoration: "underline", color: "#e83030", cursor: "pointer"}} >{error}</Typography>
            }
            { clinicianEmail && !isSubmitting && !error && savedData &&
              <Typography sx={{color: "1a8b0c"}} >Results are successfuly sent to your clinician: {" " + clinicianEmail}</Typography>
            }
            <ButtonNavigation disabled={!isReady} width="150px" onClick={() => setReportView(true)}>
              FINISH
            </ButtonNavigation>
          </Stack>
        }
      />
    </div>
    }

    <Step24
          clinicianEmail={clinicianEmail}
          handlePrev={handlePrev}
          formDataVision={formDataVision}
          conditions={conditions}
          contactFormData={contactFormData}
          CBResponse={CBResponse}
          CBResponseR={CBResponseR}
          wearGlasses={wearGlasses}
          tableDataContacts={tableDataContacts}
          tableData={tableData}
          additionalData={additionalData}
          VALNCBResponse={VALNCBResponse}
          VARNCBResponseR={VARNCBResponseR}
          VALFCBResponse={VALFCBResponse}
          VARFCBResponseR={VARFCBResponseR}
          distance={distance}
          dryEye1={dryEye1}
          dryEye2={dryEye2}
          hidden={!reportView}
    />

    </>
  );
}

export default Step22;
