import React, { useEffect, useState } from "react";
import Logo from "../components/logo/Logo";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Fixed from "../components/Fixed";
import FixedHeading from "../components/FixedHeading";
import { Box, Grid, Stack } from "@mui/material";
import MainLayout from "../components/layout/MainLayout";
import TypographyTitle from "../components/TypographyTitle";
import TypographyBody from "../components/TypographyBody";
import ButtonNavigation from "../components/ButtonNavigation";
const Welcome = ({ handleNext, handlePrev }) => {
  return (
    <MainLayout 
      headerContent={
        <TypographyTitle>
            Disclaimer - Please read carefully
        </TypographyTitle>
      }
      bodyContent={
        
        <Stack justifyContent="center" sx={{height: "100%"}}>
          <Box sx={{border: "solid 1px", px: "3rem", py: "1.2rem", borderRadius: "25px"}}>

            <TypographyBody>
                <p>
                  The <span className="underline">Virtual Vision Screener</span>{" "}
                  is
                  <span className="font-bold"> not a medical evaluation</span> and
                  shouldn't replace consultations with qualified eye specialists.
                </p>
                <p>
                  It's not designed for disease diagnosis or its management,
                  treatment, or prevention.
                </p>
                <p>
                  This assessment aims to provide a basic understanding of your
                  visual health and to suggest if a detailed eye check-up is
                  needed.
                </p>
                <p>
                  Your eyes should be examined by an eye care professional every
                  two years or earlier if you observe any vision alterations.
                </p>
                <p>
                  Vision Science Labs is not responsible for any harm or outcomes
                  stemming from the use of the Vision Assessment or the
                  information it offers.
                </p>
            </TypographyBody>
          </Box>
        </Stack>
      }
      footerContent={
        <ButtonNavigation onClick={handleNext}>
            I AGREE
        </ButtonNavigation>
      }
    />

 
  );
};

export default Welcome;
