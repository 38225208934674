import { Grid } from "@mui/material";
import React from "react";

const MainLayout = ({ headerContent, bodyContent, footerContent, bodyContentHeight }) => {
  return (
    <Grid container spacing={"10px"} sx={{height: "100%", maxHeight: "700px", maxWidth: "1880px"}} direction="column" flexWrap="nowrap" justifyContent="center" alignItems="center">
        { headerContent &&
        <Grid item sx={{maxHeight: "20%"}}>
            {headerContent}
        </Grid>
        }

      <Grid item sx={{ height: bodyContentHeight ? bodyContentHeight : null , overflowY: "hidden"}}>
        {bodyContent}
      </Grid>

      <Grid item>
        {footerContent}
      </Grid>

    </Grid>
  );
};

export default MainLayout;
