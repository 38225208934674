import { List, ListItem, Typography } from "@mui/material";
import { bodyTextSX, headerTextSX } from "../utils/constants";
import useLayoutChange from "../hooks/useLayoutChange";



const ListBoxItem = (props) => { 

    const theSize = useLayoutChange();
    const paddingY = props.paddingY ? props.paddingY : "12px";
    return (
        <ListItem
            {...props}
            sx={{ display: 'list-item', pl: 0, ml: 3, py: paddingY }}
        >
        {props.children}
        </ListItem>
  )};

export default ListBoxItem;