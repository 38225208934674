import React from "react";
import homevvs from "../../Images/home-vvs.png";
import { Button, Grid, Stack } from "@mui/material";
import Logo from "../logo/Logo";
import { LaunchGame2 } from "../../scripts/GameEngines";
import covereye from "../../Images/cover_eye.png";
import FixedHeading from "../FixedHeading";
import CoverRightEye from "../../scenes/CoverRightEye";

function Step17({ handleNext, handlePrev, isTest, setVARFCBResponseR = () => {} }) {
  const handleVisualAcuity = () => {
    if (isTest) {
      setVARFCBResponseR(
        {
          "Acuity": "20",
          "Hits": [
              5,
              0
            ]
        }
      );
      handleNext()
    } else
    LaunchGame2("VisAcuityFar", (results) => {
      console.log(results);
      setVARFCBResponseR(results);
      handleNext();
    });
  };
  return (
    <CoverRightEye handleVisualAcuity={handleVisualAcuity} />
  );
}

export default Step17;


// <div className="flex flex-col h-[10vh]  h-[80vh] justify-center  gap-[2rem] mx-auto w-max-w-[90rem] w-full align-center">
// <div className="flex  justify-between   h-full  flex-col">
//   <div className="  flex flex-col gap-[0.5rem]">
//     <div className="flex flex-col items-center  w-full px-[6rem]">
//       <FixedHeading>
//         <p className="text-[#7C6FF7] text-center pt-1 lg:text-[30px] text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
//           Maintain your distance to the screen and cover your <br /> RIGHT
//           eye.
//         </p>
//       </FixedHeading>
//     </div>
//     <div className="flex w-full justify-center py-[1.5rem]">
//       <img
//         className="lg:w-[327px] lg:h-[340px] aspect-auto max-w-[288px] max-h-[300px]"
//         src={covereye}
//       />
//     </div>
//   </div>
//   <div className="flex justify-center w-full   relative   uppercase font-Montserrat">
//     <Button
//       style={{
//         backgroundColor: "#7C6FF7",
//         borderRadius: "25px",
//         color: "white",
//         fontSize: "24px",
//         padding: "1.8rem",
//         height: "3.5rem",
//         fontWeight: "700",
//         width: "177px",
//       }}
//       appearance="primary"
//       className="button-style"
//       onClick={handleVisualAcuity}
//     >
//       NEXT
//     </Button>
//   </div>
// </div>
// </div>