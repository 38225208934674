import { useCallback, useEffect } from 'react'

function useStepUrlChange(step, setStep, clinicianEmail, isTest) {
  const handlePopState = useCallback(() => {
    // Handle back/forward navigation
    const searchParams = new URLSearchParams(window.location.search);
    const newPage = parseInt(searchParams.get('step')) || 1
    setStep(newPage);
  }, [setStep])

  useEffect(() => {
    // Update the URL whenever the 'step' state changes
    const searchParams = new URLSearchParams();
    searchParams.set('step', step);
    if (clinicianEmail) searchParams.set('clinician', clinicianEmail);
    if (isTest) searchParams.set('test', isTest);

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({}, '', newUrl);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    }
  }, [step, handlePopState])

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    }
  }, [handlePopState])
}

export default useStepUrlChange
