import { Typography } from "@mui/material";
import { headerTextIgnoreNarrowSX, headerTextSX } from "../utils/constants";
import useLayoutChange from "../hooks/useLayoutChange";



const TypographyTitle = (props) => { 

    const theSize = useLayoutChange();
    const theSX = theSize ? props.ignoreNarrow ? headerTextIgnoreNarrowSX[theSize] : headerTextSX[theSize] : {};
    return (
        <Typography
            {...props}
            variant="h2" component="h2"
            sx={theSX}
        >
        {props.children}
        </Typography>
  )};

export default TypographyTitle;