import React from "react";
import { Button } from "rsuite";
import basicInfo from "../../Images/basic-info.png";
import desk from "../../Images/Distance-from-screen.png";
import chacha from "../../Images/docchahcha.png";
import callibration1 from "../../Images/callibration-1.png";
import Navigation from "../navigations/Navigation";
import measuring from "../../Images/measuring.png";
import MainLayout from "../layout/MainLayout";
import TypographyBody from "../TypographyBody";
import ButtonNavigation from "../ButtonNavigation";
import { Stack, useMediaQuery, Box } from "@mui/material";

function Step12({ distanceCompleted, handleNext, setStep, handlePrev, useMesure }) {

  const isNarrow = useMediaQuery("(min-height: 400px) and (max-height: 680px)");
  let maxImgHeight = "300px";
  if (isNarrow) maxImgHeight = "220px";


  return (
    <>
      {distanceCompleted ? (
        <MainLayout 
          bodyContent={
              <div className="flex flex-row-reverse items-center justify-between  gap-5">
                <div className="flex justify-center w-full ">
                  <img className={isNarrow ?  "w-[20rem] aspect-auto mt-20" : "w-[30rem] aspect-auto mt-20"} src={chacha} />
                </div>
                <div className="flex flex-col items-center w-full xl:px-[3rem]">
                  <p className="text-[#7C6FF7] lg:text-[30px] text-[25px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
                    Great!
                  </p>
                  <p className="lg:text-[30px] text-[25px] xl:text-[32px] pt-6 text-center font-[400] xl:leading-[39px] font-Montserrat">
                    {" "}
                    You’ll be asked to do this multiple times for the tests coming
                    up.
                  </p>
                </div>
              </div>
          }
          footerContent={ 
              <ButtonNavigation width="155px" onClick={handleNext}>
                  NEXT
              </ButtonNavigation>
          }
        />
      ) : 
        <MainLayout 
          bodyContent={
            <Stack alignItems="center" justifyContent="center" spacing="20px" sx={{width: "100%", height: "100%"}}>
              <Stack sx={{maxHeight: maxImgHeight}}>
                <img  className="h-full aspect-auto" src={measuring} />
              </Stack>
                
                <TypographyBody textAlign="center">
                  You need to distance your face to the screen with a tape
                  measure. <br/> Please make sure you have it ready before continuing.
                </TypographyBody>
            </Stack>
          }
          footerContent={
            <Stack direction="row" spacing="50px" sx={{mt: "20px"}}>
              <ButtonNavigation width="400px" onClick={() => setStep(0)}>
                  LOG OUT
              </ButtonNavigation>
              <ButtonNavigation width="400px" onClick={handleNext}>
                  I HAVE A MEASURING DEVICE
              </ButtonNavigation>
            </Stack>
          }
        />
      }
    </>
  );
}

export default Step12;
