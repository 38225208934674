import React from 'react'

function Logo({ width = '272px', height = '120.43px' }) {
  return (
    <div className='flex place-content-center'>
      <div className=''>
        <img src='./Assets/VSL_Logo.svg' width={width} height={height} alt='' />
      </div>
    </div>
  )
}

export default Logo
