import React from "react";
import bullet from "../../Images/bullet-point.svg";
import bg from "../../Images/smexyimg.png";
import { Box, Button, Grid, ListItemButton, Stack } from "@mui/material";
import Fixed from "../Fixed";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import TypographyTitle from "../TypographyTitle";
import TypographyBody from "../TypographyBody";
import ButtonNavigation from "../ButtonNavigation";
import TextAndImage from "../layout/TextAndImage";
import TextBoxAndImage from "../layout/TextBoxAndImage";
import ListBox from "../ListBox";
import ListBoxItem from "../ListBoxItem";

function Step3({ handleNext }) {
  return (
    <MainLayout 
      headerContent={
        <TypographyTitle>
            The following tests will cover:
        </TypographyTitle>
      }
      bodyContent={
        <TextBoxAndImage 
          boxContent={
            <ListBox>
              <ListBoxItem>Visual Health Questions</ListBoxItem>
                <ListBoxItem>Reading Distance Measurement</ListBoxItem>
                <ListBoxItem>Computer Distance Measurement</ListBoxItem>
                <ListBoxItem>Retinal Disease Screener</ListBoxItem>
                <ListBoxItem>Eye Strain Test</ListBoxItem>
                <ListBoxItem>Dry Eye Test</ListBoxItem>
              </ListBox>
          }
          imageSrc={bg} imageAlt="Start Test Image"
          spacing="20px"
        />

      }
      footerContent={
        <ButtonNavigation onClick={handleNext}>
          START
        </ButtonNavigation>
      }
    />
  );
}

export default Step3;
