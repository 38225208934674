
import SimpleForm from './components/RegisterForm';
import FullScene from './scenes/FullScene';
function App() {
  return (
    <>
    <div id="MetaEngine" ></div>
    <FullScene/>
    </>
  );
}

export default App;
