import React from "react";
import visualacuity from "../Images/gifs/visual-acuity.gif";
import covereye from "../Images/cover_eye.png";
import MainLayout from "../components/layout/MainLayout";
import TypographyTitle from "../components/TypographyTitle";
import DoubleColumnInstruction from "../components/layout/DoubleColumnInstruction";
import ButtonNavigation from "../components/ButtonNavigation";
import TypographyBody from "../components/TypographyBody";
import { Stack } from "@mui/material";

function TestInstructionE({ handleVisualAcuity }) {

  return (
    <MainLayout 
      headerContent={
        <TypographyTitle>
          Test Instructions
        </TypographyTitle>
      }
      bodyContent={
        <DoubleColumnInstruction 
          instruction1={
            <Stack spacing="8px">
              <TypographyBody textAlign="center">
                Step 1
              </TypographyBody>
              <TypographyBody>
                Cover the indicated eye with your hand.
              </TypographyBody>
            </Stack>
          }
          instruction2={
            <Stack spacing="8px">
              <TypographyBody textAlign="center">
                Step 2
              </TypographyBody>
              <TypographyBody>
                Using your other hand, indicate the open direction of the E symbols.
              </TypographyBody>
            </Stack>
          }
          imageSrc1={covereye}
          imageSrc2={visualacuity}
        />
      }
      footerContent={
        <ButtonNavigation width="250px" onClick={handleVisualAcuity}>
          START TEST
        </ButtonNavigation>
      }
    />
  );
}

export default TestInstructionE;
