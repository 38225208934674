import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import MainLayout from "./MainLayout";
import { bodyTextSX, headerTextSX } from "../../utils/constants";
import useLayoutChange from "../../hooks/useLayoutChange";
import TypographyTitle from "../TypographyTitle";
import TypographyBody from "../TypographyBody";
import ButtonNavigation from "../ButtonNavigation";

const TextAndImage = ({ imageSrc, imageAlt, textComponent, imageMaxHeight, spacing  }) => {


  return (
            <Stack direction="row" alignItems="center" spacing={spacing ? spacing : 0} sx={{ height: "100%"}} >
                {textComponent}
                <Stack alignItems="center" justifyContent="center" sx={{height: "100%", overflow: "hidden", maxHeight: imageMaxHeight ? imageMaxHeight : "unset"}}>
                    <img
                        className="h-full w-auto object-cover"
                        src={imageSrc}
                        alt={imageAlt}
                    ></img>
                </Stack>
            </Stack>
  );
};

export default TextAndImage;
