import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import homevvs from "../../Images/home-vvs.png";
import Navigation from "../navigations/Navigation";
import Logo from "../logo/Logo";
import step17 from "../../Images/blackdots.png";
import dr from "../../Images/drwithgirl.png";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import ButtonNavigation from "../ButtonNavigation";
import TextBoxAndImage from "../layout/TextBoxAndImage";
import TypographyTitle from "../TypographyTitle";
import TypographyBody from "../TypographyBody";

function Step18({ handleNext, handlePrev }) {
  return (
    <MainLayout 
      headerContent={
        <TypographyTitle textAlign="center">
            Next, we’re going to see if you are experiencing any abnormalities
            in your vision.
        </TypographyTitle>
      }
      bodyContent={
        <TextBoxAndImage 
          boxContent={
            <Stack spacing="40px">
              <TypographyBody>
                  This will help determine if you are experiencing eye conditions
                  requiring urgent care.
              </TypographyBody>
              <TypographyBody>
                  <p className="font-[700]">
                    You may wear your glasses now.
                  </p>
              </TypographyBody>
            </Stack>

          }
          imageSrc={dr}
          spacing="20px"
        />
      }
      footerContent={
        <ButtonNavigation width="350px" onClick={handleNext}>
          START INSTRUCTIONS
        </ButtonNavigation>
      }
    />
  );
}

export default Step18;
