import React, { useState, useEffect } from "react";

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

import TextField from "@mui/material/TextField";
import {
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  useMediaQuery,
  Typography,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FixedHeading from "../components/FixedHeading";
import MainLayout from "../components/layout/MainLayout";
import ButtonNavigation from "../components/ButtonNavigation";
import TypographyTitle from "../components/TypographyTitle";
function BasicVisionInfo12(props) {

  const matchDownLG = useMediaQuery('(max-width:1200px)');
  const matchDownXL = useMediaQuery('(max-width:1450px)');

  const options = ["1 Month", "2 Months", "3 Months"];
  const birthSexOptions = ["Male", "Female", "Prefere not to disclose"];
  const lastEyeExamOptions = ["Past 6 Months", "Past Year", "Past 2 Years", "Over 2 Years"];

  const formik = useFormik({

    initialValues: {
        birthYear: props.isTest ? '1990' : '' ,
        birthSex: props.isTest ? "Prefere not to disclose" : '' ,
        lastEyeExam: props.isTest ? "Past Year" :  '' ,
        firstName: props.isTest ? "John" : '' ,
        lastName: props.isTest ? "Doe" : '' ,
    },
    validationSchema: yup.object({
      birthYear: yup.number().required('Birth year is required').min(1900, "Enter a valid year").max(2100, "Enter a valid year"),
      birthSex: yup.string().required('Required'),
      lastEyeExam: yup.string().required('Required'),
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
    }),
    onSubmit: async (values, {resetForm}) => {
      props.setFormDataVision({ ...props.formData, ...values });
      props.handleNext();
    }
    });

    useEffect(() => {
      // handle for isTest
      if (props.isTest) {
        props.handleRadioChange({target: {name: "wearGlasses" , value: "yes" }});
        for (let i = 0 ;  i < 6;  i++ ) {
          props.handleChangeTable1({target: {value: "12" }}, 0, i);
          props.handleChangeTable1({target: {value: "12" }}, 1, i);
        }
        for (let i = 0 ; i < 2 ; i++) {
          props.handleChangeTableContacts({target: {value: "10" }}, 0, i);
          props.handleChangeTableContacts({target: {value: "10" }}, 1, i);
        }
        
      }

    }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission, e.g., send the data to a server
    console.log("Form data submitted:", props.formData);
  };
  const enableConditions = () => {
    return !(props.wearGlasses === "yes");
  };

  const side = matchDownLG ? ["Right", "Left"] : ["Right (O.D.)", "Left (O.S.)"];
  const containerStyle = {
    padding: 100, // Remove padding
  };

  return (
    <MainLayout
      bodyContent={
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing="12px">
            <Grid item xs={12} sx={{pb: "12px"}}>
              <TypographyTitle >
                About You
              </TypographyTitle>
            </Grid>
            <Grid item>
                <TextField
                  fullWidth
                  type="number"
                  name="birthYear"
                  label="Birth Year"
                  variant="outlined"
                  value={formik.values.birthYear}
                  onChange={formik.handleChange}
                  helperText={ formik.touched.birthYear && formik.errors.birthYear}
                  error={Boolean(formik.errors.birthYear && formik.touched.birthYear)}
                  sx={{width: "200px"}}
                  // inputProps={{ style: { fontSize: 24 } }} // font size of input text
                />
            </Grid>
            <Grid item>
               <FormControl variant="outlined" fullWidth error={Boolean(formik.errors.birthSex && formik.touched.birthSex)} >
                  <InputLabel id="birth-sex-select-label">Birth Sex</InputLabel>
                  <Select
                    labelId="birth-sex-select-label"
                    id="birthSex"
                    name="birthSex"
                    value={formik.values.birthSex}
                    onChange={formik.handleChange}
                    label="Birth Sex" // Ensures the label moves up
                    sx={{width: "300px"}}
                    // inputProps={{ style: { fontSize: 24 } }}
                  >
                    {birthSexOptions.map((option) => (
                      <MenuItem sx={{fontSize: "20px"}} key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  { formik.touched.birthSex && formik.errors.birthSex &&
                    <FormHelperText>
                      {formik.errors.birthSex}
                    </FormHelperText>
                  }
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl variant="outlined" error={Boolean(formik.errors.lastEyeExam && formik.touched.lastEyeExam)} >
                  <InputLabel id="last-eye-exam-select-label">
                    Last Eye Exam
                  </InputLabel>
                  <Select
                    labelId="last-eye-exam-select-label"
                    id="lastEyeExam"
                    name="lastEyeExam"
                    value={formik.values.lastEyeExam}
                    onChange={formik.handleChange}
                    label="Last Eye Exam"
                    sx={{width: "200px"}}
                    // inputProps={{ style: { fontSize: 24 } }}
                  >
                    {lastEyeExamOptions.map((option) => (
                      <MenuItem sx={{fontSize: "20px"}} key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  { formik.touched.lastEyeExam && formik.errors.lastEyeExam &&
                    <FormHelperText>
                      {formik.errors.lastEyeExam}
                    </FormHelperText>
                  }
                </FormControl>
            </Grid>
            <Grid item xs={props.wearGlasses == "no" ? 12 : false}>
              <Stack direction="row" alignItems="center" spacing="12px">
                <TextField
                  sx={{width: props.wearGlasses == "no" ? "355px" : "100%", pb: Boolean(formik.errors.firstName && formik.touched.firstName || (!formik.errors.lastName || !formik.touched.lastName)) ? 0 : 3}}
                  type="text"
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  helperText={ formik.touched.firstName && formik.errors.firstName}
                  error={Boolean(formik.errors.firstName && formik.touched.firstName)}
                  // inputProps={{ style: { fontSize: 24 } }}
                />
                <TextField
                  sx={{width: props.wearGlasses == "no" ? "355px" : "100%", pb: Boolean(formik.errors.lastName && formik.touched.lastName || (!formik.errors.firstName || !formik.touched.firstName)) ? 0 : 3 ? 0 : 3}}
                  type="text"
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  helperText={ formik.touched.lastName && formik.errors.lastName}
                  error={Boolean(formik.errors.lastName && formik.touched.lastName)}
                  // inputProps={{ style: { fontSize: 24 } }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Stack direction="row" spacing={6} alignItems="center" >
                    <FormLabel component="legend">
                      <Typography fontSize={ matchDownLG ? "20px" : "24px"} >
                        {" "}
                        Do you wear glasses or contacts?{" "}
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      row
                      name="wearGlasses"
                      value={props.wearGlasses}
                      onChange={props.handleRadioChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label={<Typography fontSize={ matchDownLG ? "20px" : "24px"}> Yes </Typography>}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label={<Typography fontSize={ matchDownLG ? "20px" : "24px"}> No </Typography>}
                      />
                    </RadioGroup>
                </Stack>
              </FormControl>
            </Grid>

            {props.wearGlasses === "yes" ? (
            <Grid item xs={12}>
            <Stack spacing="12px">

              <Typography textAlign="start" fontSize={ matchDownLG ? "20px" : "24px"}>
                Enter your prescription if you want it in the report.{" "}
              </Typography>
              
              <Stack>
                <TableContainer
                  component={Paper}
                  style={{ opacity: enableConditions() ? 0.5 : 1.0, boxShadow: "unset" }}
                >
                  <Table padding="none">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                          }}
                        >
                          Glasses
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          { matchDownXL ? "SPH" : "SPEAR (SPH)" }
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                          }}
                        >
                          { matchDownXL ? "CYL" : "CYLINDER (CYL)" }
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          { matchDownXL ? "AX" : "AXIS (AX)" }
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          ADD
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                          }}
                        >
                          PRISM
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                          }}
                        >
                          PD
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.tableData.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          sx={{ backgroundColor: "white !important" }}
                        >
                          <TableCell
                            sx={{
                              textAlign: "start",
                              paddingLeft: "10px",
                              fontSize: "20px",
                              backgroundColor: "#dddddd",
                            }}
                          >
                            {side[rowIndex]}
                          </TableCell>
                          {row.map((cellValue, colIndex) => (
                            <TableCell
                              sx={{ textAlign: "start", fontSize: "20px" }}
                              key={colIndex}
                            >
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                value={cellValue}
                                onChange={(e) =>
                                  props.handleChangeTable1(e, rowIndex, colIndex)
                                }
                                sx={{ '& .MuiOutlinedInput-notchedOutline' : {height: "40px!important"} }}
                                inputProps={{
                                    style: { textAlign: "center", paddingTop: "3px", paddingBottom: "3px"  },
                                }}
                                disabled={enableConditions()}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                          }}
                        >
                          Contacts
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                          }}
                        >
                          { matchDownXL ? "BC" : "Base Curve (BC)" }
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "start",
                            paddingLeft: "10px",
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                          }}
                        >
                          { matchDownXL ? "Dia" : "Diametre (Dia)" }
                        </TableCell>
                    
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.tableDataContacts.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          sx={{ backgroundColor: "white !important" }}
                        >
                          <TableCell
                            sx={{
                              textAlign: "start",
                              paddingLeft: "10px",
                              fontSize: "20px",
                              backgroundColor: "#dddddd",
                            }}
                          >
                            {side[rowIndex]}
                          </TableCell>
                          {row.map((cellValue, colIndex) => (
                            <TableCell
                              sx={{ textAlign: "start", fontSize: "20px" }}
                              key={colIndex}
                            >
                              <TextField
                                fullWidth
                                type="text"
                                value={cellValue}
                                onChange={(e) =>
                                  props.handleChangeTableContacts(
                                    e,
                                    rowIndex,
                                    colIndex
                                  )
                                }
                                size="small"
                                sx={{ '& .MuiOutlinedInput-notchedOutline' : {height: "40px!important"} }}
                                inputProps={{
                                    style: { textAlign: "center", paddingTop: "3px", paddingBottom: "3px"  },
                                }}
                                disabled={enableConditions()}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </Stack>

              
            </Stack>
            </Grid>
          ) : (
            <>
            </>
          )}

          </Grid>
        </form>
      }
      footerContent={
        <ButtonNavigation onClick={formik.handleSubmit}>
          NEXT
        </ButtonNavigation>
      }
    />
  );
}

export default BasicVisionInfo12;
