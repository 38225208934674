import React from "react";
import basicInfo from "../../Images/basic-info.png";
import drr from "../../Images/drwithgirl.png";
import { Button, Grid, Stack, Typography } from "@mui/material";
import patientConsult from "../../Images/patient-consult.png";
import MainLayout from "../layout/MainLayout";
import TextBoxAndImage from "../layout/TextBoxAndImage";
import TypographyBody from "../TypographyBody";
import ButtonNavigation from "../ButtonNavigation";
function Step4({ handleNext, handlePrev }) {
  return (
    <MainLayout 
      bodyContent={
        <TextBoxAndImage 
          boxContent={
            <TypographyBody isLarge lineHeightPlus="15px">
                  First, lets get some basic information about you. <br /> Your
                  answers will help determine if you have have any problems with
                  your vision.
            </TypographyBody>
          }
          imageSrc={patientConsult} imageAlt="Basic Information Image" spacing="20px"
        />
      }
      footerContent={
        <ButtonNavigation onClick={handleNext}>
          NEXT
        </ButtonNavigation>
      }
    />
  );
}

export default Step4;
