import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import homevvs from "../../Images/home-vvs.png";
import Navigation from "../navigations/Navigation";
import Logo from "../logo/Logo";
import step17 from "../../Images/eyestrain.png";
import step18 from "../../Images/health].png";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import TypographyTitle from "../TypographyTitle";
import TextBoxAndImage from "../layout/TextBoxAndImage";
import ListBox from "../ListBox";
import ListBoxItem from "../ListBoxItem";
import ButtonNavigation from "../ButtonNavigation";

function Step21({ handleNext, handlePrev }) {
  return (
    <MainLayout 
      headerContent={
        <TypographyTitle>
            Do your eyes work well?
        </TypographyTitle>
      }
      bodyContent={
        <TextBoxAndImage
          boxContent={
            <ListBox>
              <ListBoxItem paddingY="13px">
                Millions of people have been diagnosed with digital eye
                strain since 2021 impacting work and academics
              </ListBoxItem>
              <ListBoxItem paddingY="13px">
                The following will ask you questions to help determine if
                you’re experiencing eye strain and dry eye.
              </ListBoxItem>
              <ListBoxItem paddingY="13px">
                You may wear your glasses.
              </ListBoxItem>
            </ListBox>
          }
          imageSrc={step18}
          spacing="20px"
        />
      }
      footerContent={
          <ButtonNavigation width="250px" onClick={handleNext}>
            START TEST
          </ButtonNavigation>
      }
    />
  );
}

export default Step21;
