export function KatalyticCombo(EID, settings) {
  const combo = Object.assign({}, Katalyst)
  const _Exercise_Metadata_ = [] // Remove
  const metadata = _Exercise_Metadata_[EID]
  // combo.TrueName = _Get_Full_Title_(metadata, settings);
  combo.Legacy = metadata.legacy

  if (metadata.DistO) {
    if (typeof metadata.DistO === 'function') {
      combo.TrueDistance = metadata.DistO(settings)
    } else {
      combo.TrueDistance = metadata.DistO
    }
  }

  if (metadata.pregame) {
    combo.Pregame = metadata.pregame
    combo.Pregame._eid = EID

    const inst = metadata.pregame.inst
    combo.TrueInst = typeof inst === 'function' ? inst(settings) : inst

    const img = metadata.pregame.img
    combo.TrueImg = typeof img === 'function' ? img(settings) : img

    const HW = metadata.pregame.hardware
    combo.TrueHW = typeof HW === 'function' ? HW(settings) : HW
  }

  const _Exercise_Engine_Map_ = [] // Remove
  const _Settings_Procs_ = [] // Remove

  combo.ENG = _Exercise_Engine_Map_[EID]
  const SEND = {}
  Object.entries(settings).forEach((e) => {
    const parser = _Settings_Procs_[e[0]]
    if (parser) {
      combo[e[0]] = parser(e[1])
      SEND[e[0]] = parser(e[1])
    } else {
      combo[e[0]] = e[1]
      SEND[e[0]] = e[1]
    }
  })

  if (metadata.pregame && metadata.pregame.reqL) {
    combo['_SEND_'] = SEND
  }

  return combo
}

/////GAME INSTRUCTIONS TEMPLATE////

function DrawTitle(dot) {
  const titleText = window.Meta.get('TrueName')

  if (window.Meta.Stage.Height < 1080) {
    const TITLE = window.Meta.Text(titleText, { fill: 'white', size: 32 })
    TITLE.Top = -320
    TITLE.Left = 0
    TITLE.Right = 0
  } else {
    const TITLE = window.Meta.Text(titleText, { fill: 'white', size: 48 })
    TITLE.Top = -500
    TITLE.Left = 0
    TITLE.Right = 0
  }

  //~ const CIRC1 = Meta.Circle(10,(dot == 1 ? {fill:"#ffffff"} : {line:"#ffffff", stroke:4}));
  //~ CIRC1.Top = -440;
  //~ CIRC1.Right = -30;

  //~ const CIRC2 = Meta.Circle(10, (dot == 2 ? {fill:"#ffffff"} : {line:"#ffffff", stroke:4}));
  //~ CIRC2.Top = -440;
  //~ CIRC2.Left = -10;

  //~ const CIRC3 = Meta.Circle(10, (dot == 3 ? {fill:"#ffffff"} : {line:"#ffffff", stroke:4}));
  //~ CIRC3.Top = -440;
  //~ CIRC3.Left = 30;
}

function DrawNav() {
  const Small = window.Meta.Stage.Height < 1080

  const BW = Small ? 135 : 150
  const BH = Small ? 35 : 50
  const BR = Small ? 74 : 100

  // if (!window.Meta.get("FirstPage")){
  // 	const BBUT = window.Meta.Image(BW, BH, "/ico/base/NavBack.svg");
  // 	BBUT.onclick = function(){window.Meta.Return("back")};
  // 	BBUT.Bot = Small?320:475;
  // 	BBUT.Right = Small?-5:-30;
  // }else{
  // 	const BBUT = window.Meta.Image(BW, BH, "/ico/base/NavBox.svg");
  // 	BBUT.Bot = Small?320:475;
  // 	BBUT.Right = Small?-5:-30;
  // }

  // if (!window.Meta.get("LastPage")){
  // 	const BUT = window.Meta.Image(BW, BH, "/ico/base/NavNext.svg");
  // 	BUT.onclick = function(){window.Meta.Return("next")};
  // 	BUT.Bot = Small?320:475;
  // 	BUT.Left = Small?5:30;
  // }else{
  // 	const BUT = window.Meta.Image(BW, BH, "/ico/base/NavBox.svg");
  // 	BUT.Bot = Small?320:475;
  // 	BUT.Left = Small?5:30;
  // }

  // const START = window.Meta.Image(4*BR, 4*BR, "/ico/base/NavStart.svg");
  // START.onclick = function(){window.Meta.Return("link")};
  // START.Bot = Small?240:200;
  // START.Left = -BR/2;
  window.Meta.Return('link')
}

const TestPage = {
  Start: function () {
    DrawTitle(1)
    window.Meta.Stage.Background('#000000')

    var eyetext = ''
    if (window.Meta.get('TrueSide') === 'Right') {
      eyetext = 'Cover your LEFT eye.<br>'
    } else if (window.Meta.get('TrueSide') === 'Left') {
      eyetext = 'Cover your RIGHT eye.<br>'
    }

    const Small = window.Meta.Stage.Height < 1080

    const TEXT = window.Meta.Text(eyetext + window.Meta.get('TrueInst'), {
      fill: 'white',
      size: Small ? 28 : 40,
    })
    TEXT.Top = Small ? -280 : -400
    TEXT.Left = 0
    TEXT.Right = 0

    const IMG = window.Meta.Image(640, 360, window.Meta.get('TrueImg'))
    IMG.Top = Small ? -110 : -100
    IMG.Left = -320

    DrawNav()
  },
}

const DotPage = {
  Start: function () {
    DrawTitle(2)
    window.Meta.Stage.Background('#000000')

    const FOCUS = window.Meta.Circle(10, { fill: '#cccccc' })
    FOCUS.Top = -10
    FOCUS.Left = -10

    const Small = window.Meta.Stage.Height < 1080
    const rad = window.MetaVision.BlindRad()

    const BLINDL = window.Meta.Circle(rad, { fill: '#cc00cc' })
    BLINDL.Top =
      window.MetaVision.CmToPix(window.MetaVision.ArcToCm(1.5 * 3600)) - rad
    BLINDL.Left =
      window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15 * 3600)) - rad

    const BLINDR = window.Meta.Circle(rad, { fill: '#cc00cc' })
    BLINDR.Top =
      window.MetaVision.CmToPix(window.MetaVision.ArcToCm(1.5 * 3600)) - rad
    BLINDR.Right =
      -window.MetaVision.CmToPix(window.MetaVision.ArcToCm(15 * 3600)) + rad

    const TEXT2 = window.Meta.Text(
      'Position your head ' +
        window.Meta.get('TrueDistance') +
        'cm (' +
        Math.round(window.Meta.get('TrueDistance') / 2.54) +
        'in) from the screen.<br>If needed, use the purple dots to position your head from the screen.',
      { fill: 'white', size: Small ? 28 : 40 }
    )
    TEXT2.Top = Small ? -280 : -350
    TEXT2.Left = 0
    TEXT2.Right = 0

    DrawNav()
  },
}

const LinkLoadPage = {
  Start: function () {
    const ro = window.Meta.Text('Generating Game Link...', {
      fill: '#ffffff',
      size: 50,
    })
    ro.Top = 0
    ro.Left = 0
    ro.Right = 0
  },
}

export const LinkPage = {
  //both as in url link and "linking" client to game
  Start: function () {
    const inst = window.Meta.get('GameInst')

    const ONE = window.Meta.Text('1', { fill: '#ffffff', size: 40 })
    ONE.Top = -350
    ONE.Left = 0
    ONE.Right = 0

    const QR = window.Meta.QRC(
      400,
      400,
      'https://visiplay.vercel.app/link?code=' + inst.code
    )
    QR.Top = -350
    QR.Left = -200

    const ONETXT = window.Meta.Text(
      'Open the Camera app on your phone and scan the QR code below.',
      { fill: '#ffffff', size: 42 }
    )
    ONETXT.Top = -450
    ONETXT.Left = 0
    ONETXT.Right = 0

    const TWO = window.Meta.Text(
      'Having trouble? On your phone go to:<br>https://visiplay.vercel.app/link',
      { fill: '#ffffff', size: 42 }
    )
    TWO.Top = 150
    TWO.Left = 0
    TWO.Right = 0

    const TWOTXT = window.Meta.Text('Enter the  code:', {
      fill: '#ffffff',
      size: 42,
    })
    TWOTXT.Top = 300
    TWOTXT.Left = 0
    TWOTXT.Right = 0

    const CODE = window.Meta.Text(inst.code, { fill: '#ffffff', size: 40 })
    CODE.Top = 350
    CODE.Left = 0
    CODE.Right = 0

    // const THREE = window.Meta.Text("3", {fill:"#ffffff", size:40});
    // THREE.Top = 350;
    // THREE.Left = 0;
    // THREE.Right = 0;

    // const THREETXT = window.Meta.Text("Follow the instructions on your phone.", {fill:"#ffffff", size:42});
    // THREETXT.Top = 400;
    // THREETXT.Left = 0;
    // THREETXT.Right = 0;

    inst.On('STATE', (state) => {
      if (state == 2) {
        window.Meta.Return('count')
      }
    })
  },
}

const DotSchool = {
  Start: function () {
    const Small = window.Meta.Stage.Height < 1080
    window.Meta.Stage.Background('#000000')

    const TITLE = window.Meta.Text('Blind Spot Distance Calibration', {
      fill: 'white',
      size: Small ? 32 : 48,
    })
    TITLE.Top = Small ? -320 : -500
    TITLE.Left = 0
    TITLE.Right = 0

    const TEXT = window.Meta.Text(
      'Cover or close one of your eyes.<br>Focus on the WHITE dot in the center of the screen.<br>Move your head closer or further from the screen until the PURPLE dot disappears.<br>If you covered or closed your LEFT eye, the RIGHT dot should dissapear.<br>If you covered or closed your RIGHT eye, the LEFT dot should dissapear.',
      { fill: 'white', size: Small ? 28 : 40 }
    )
    TEXT.Top = Small ? -280 : -400
    TEXT.Left = 0
    TEXT.Right = 0

    const IMG = window.Meta.Image(1280, 360, '/gif/BlindSpotInst.gif')
    IMG.Top = Small ? -85 : -60
    IMG.Left = -640

    const BUT = window.Meta.Button(
      'Got It',
      function () {
        window.Meta.Return('dot')
      },
      { fill: '#000000', line: '#ffffff', stroke: 4, size: Small ? 28 : 40 }
    )
    BUT.Bot = Small ? 320 : 500
    BUT.Left = Small ? -50 : -60
  },
}

export const ReadyScreen = {
  Start: function () {
    DrawTitle(3)
    window.Meta.Stage.Background('#000000')
    const Pregame = window.Meta.get('Pregame')

    const Small = window.Meta.Stage.Height < 1080
    const IcoSize = Small ? 75 : 100

    var LineTop = Small ? -270 : -380

    if (Pregame.reqD) {
      const ico = window.Meta.Image(
        IcoSize,
        IcoSize,
        '/ico/base/PregameDistance.svg'
      )
      ico.Top = LineTop
      ico.Left = -IcoSize / 2
      LineTop += IcoSize + 5

      const TEXT2 = window.Meta.Text(
        'Keep your head still at ' +
          window.Meta.get('TrueDistance') +
          'cm (' +
          Math.round(window.Meta.get('TrueDistance') / 2.54) +
          'in) from the screen.',
        { fill: 'white', size: Small ? 28 : 36 }
      )
      TEXT2.Top = LineTop
      TEXT2.Left = 0
      TEXT2.Right = 0

      LineTop += 65
    }

    var HWT = ''
    var HICO = ''
    const HW = window.Meta.get('TrueHW')
    if (HW == '3DG') {
      HWT = 'Put on 3D Glasses.'
      HICO = '/ico/base/Pregame3dGlasses.svg'
    } else if (HW == 'FLIP') {
      HWT = 'Put your Flippers in the starting position.'
      HICO = '/ico/base/Instructions - Flippers.svg'
    }
    if (HWT != '') {
      const ico = window.Meta.Image(IcoSize, IcoSize, HICO)
      ico.Top = LineTop
      ico.Left = -IcoSize / 2
      LineTop += IcoSize + 5

      const TEXT4 = window.Meta.Text(HWT, {
        fill: 'white',
        size: Small ? 28 : 36,
      })
      TEXT4.Top = LineTop
      TEXT4.Left = 0
      TEXT4.Right = 0

      LineTop += 65
    }

    var DoThingTxt = ''
    var DOCO = ''
    function Start() {
      window.Meta.Return('count')
    }
    const mainI = window.Meta.get('Pregame').mainI
    switch (mainI) {
      case 'Arrow':
        DoThingTxt = 'Push any of the arrow keys to start.'
        DOCO = '/ico/base/PregameArrowKeys.svg'
        window.Meta.KeyDown('Up', Start)
        window.Meta.KeyDown('Down', Start)
        window.Meta.KeyDown('Left', Start)
        window.Meta.KeyDown('Right', Start)
        break
      case 'Mouse':
        DoThingTxt = 'Click the Start button to continue.'
        DOCO = '/ico/base/PregameMouse.svg'
        break
      case 'Accept':
        DoThingTxt = 'Push the SPACEBAR to start.'
        DOCO = '/ico/base/PregameSpacebar.svg'
        window.Meta.KeyDown('Accept', Start)
        break
      case 'Full':
        DoThingTxt = 'Push any letter key to start.'
        DOCO = '/ico/base/PregameKeyboard.svg'
        window.Meta.KeyDown('Full', Start)
        break
      case 'bdpq':
        DoThingTxt = 'Click the mouse here or push any key to start.'
        DOCO = '/ico/base/PregameMouse.svg'
        window.Meta.KeyDown('Full', Start)
        break
      default:
        DoThingTxt = ''
    }

    if (DOCO !== '') {
      const ico = window.Meta.Image(IcoSize * 2, IcoSize * 2, DOCO)
      ico.Bot = Small ? 170 : 250
      ico.Left = -IcoSize
    }

    if (DoThingTxt !== '') {
      const TEXT5 = window.Meta.Text(DoThingTxt, {
        fill: 'white',
        size: Small ? 28 : 36,
      })
      TEXT5.style.textDecoration = 'underline'
      TEXT5.Bot = Small ? 200 : 300
      TEXT5.Left = 0
      TEXT5.Right = 0
    }

    DrawNav()
  },
}

export const CountdownScreen = {
  Start: function () {
    const TEXT = window.Meta.Text('3', { fill: 'white', size: 48 })
    TEXT.Top = -24
    TEXT.Left = 0
    TEXT.Right = 0

    window.Meta.set('txt', TEXT)
    window.Meta.set('start', Date.now())
  },
  Tick: function () {
    const start = window.Meta.get('start')
    const NOW = Date.now()

    // if (window.Meta.get("Pregame").whiteout || window.Meta.get("ContrastMode")=="BoW"){
    if (window.Meta.get('ContrastMode') === 'BoW') {
      var val = Math.round(((NOW - start) / 3000) * 255).toString(16)
      val = val.length === 1 ? '0' + val : val
      window.Meta.Stage.Background('#' + val + val + val)
    } else if (window.Meta.get('ContrastMode') == 'BoG') {
      var val = Math.round(((NOW - start) / 3000) * 153).toString(16)
      val = val.length === 1 ? '0' + val : val
      window.Meta.Stage.Background('#' + val + val + val)
    }

    if (NOW >= start + 3000) {
      window.Meta.get('txt').Text = ''
      window.Meta.Return('launch')
    } else if (Date.now() >= start + 2000) {
      window.Meta.get('txt').Text = '1'
    } else if (Date.now() >= start + 1000) {
      window.Meta.get('txt').Text = '2'
    }
  },
}

export const Katalyst = {
  Start: function () {
    var LR = window.Meta.get('Side') === 'Monocular'
    if (LR) {
      window.Meta.set('TrueSide', 'Right')
    } else {
      if (window.Meta.get('Side') === 'Right Only') {
        window.Meta.set('TrueSide', 'Right')
      } else if (window.Meta.get('Side') === 'Left Only') {
        window.Meta.set('TrueSide', 'Left')
      }
    }

    if (window.Meta.get('TrueDistance')) {
      if (window.Meta.get('TrueDistance') == '20/20') {
        const MinCm = window.MetaVision.PixToCm(5)
        const ArcInRadians = (5 / 60) * (Math.PI / 180)
        const ReqDistance = Math.max(
          Math.ceil((1 / Math.tan(ArcInRadians)) * MinCm),
          50
        )
        window.Meta.set('TrueDistance', ReqDistance)
      }
      window.MetaVision.PatientDistance = window.Meta.get('TrueDistance')
    } else {
      window.Meta.set('TrueDistance', window.Cali.PatientDistance)
    }

    const TrueResults = {}
    var UseDot = false

    function StateEngine(ret) {
      window.Meta.Stage.Clear()
      switch (ret) {
        case 'next':
          pagedex = Math.min(pagedex + 1, PageOrder.length - 1)
          window.Meta.set('FirstPage', pagedex == 0)
          window.Meta.set('LastPage', pagedex >= PageOrder.length - 1)
          StateEngine(PageOrder[pagedex])
          break
        case 'back':
          pagedex = Math.max(pagedex - 1, 0)
          window.Meta.set('FirstPage', pagedex == 0)
          window.Meta.set('LastPage', pagedex >= PageOrder.length - 1)
          StateEngine(PageOrder[pagedex])
          break
        case 'start':
          window.Meta.SubDo(TestPage, StateEngine)
          break
        case 'dot':
          UseDot = true
          window.Meta.SubDo(DotPage, StateEngine)
          break
        case 'school':
          window.Meta.SubDo(DotSchool, StateEngine)
          break
        case 'link':
          if (Pregame.reqL) {
            if (window.Meta.get('GameInst')) {
              window.Meta.SubDo(LinkPage, StateEngine)
            } else {
              // this will probably change
              window.Meta.SubDo(LinkLoadPage, StateEngine)
              window.Meta.GameState().then((inst) => {
                window.Meta.set('GameInst', inst)
                // inst.Write("eid", Pregame._eid);
                inst.Write('eid', 'PhoneAccomm')
                // inst.Write("SEND", window.Meta.get("_SEND_"));
                inst.Write('SEND', {
                  ContrastMode: 'WoB',
                  FaarDistance: 7,
                  NearSize: 'Small',
                  Time: 1,
                  Type: 'Left',
                })
                inst.Write('STATE', 1)
                window.Meta.Stage.Clear()
                window.Meta.SubDo(LinkPage, StateEngine)
              })
            }
          } else {
            StateEngine('count')
          }
          break
        case 'ready':
          window.Meta.SubDo(ReadyScreen, StateEngine)
          break
        case 'count':
          window.Meta.SubDo(CountdownScreen, StateEngine)
          break
        case 'launch':
          if (window.Meta.get('Legacy')) {
            window.Meta.SubDo(LegacyWrapper, StateEngine)
          } else {
            window.Meta.SubDo(window.Meta.get('ENG'), StateEngine)
          }
          break
        default: //some kind of results came back
          if (window.Meta.get('TrueSide') === 'Right') {
            if (ret.Warning) {
              TrueResults.Warning = TrueResults.Warning
                ? Math.max(TrueResults.Warning, ret.Warning)
                : ret.Warning
              ret.Warning = null
            }
            TrueResults.Right = ret
            if (LR) {
              window.Meta.set('TrueSide', 'Left')
              if (UseDot) {
                window.Meta.SubDo(DotPage, StateEngine)
              } else {
                //~ Meta.SubDo(ReadyScreen, StateEngine);
                window.Meta.SubDo(TestPage, StateEngine)
              }
            } else {
              ResultsReturn(TrueResults)
            }
          } else if (window.Meta.get('TrueSide') === 'Left') {
            if (ret.Warning) {
              TrueResults.Warning = TrueResults.Warning
                ? Math.max(TrueResults.Warning, ret.Warning)
                : ret.Warning
              ret.Warning = null
            }
            TrueResults.Left = ret
            ResultsReturn(TrueResults)
          } else {
            ResultsReturn(ret)
          }
          break
      }
    }

    const Pregame = window.Meta.get('Pregame')
    const PageOrder = ['start']
    const HighPrecisionDistance = false
    var pagedex = -1
    if (Pregame) {
      if (Pregame.reqD && HighPrecisionDistance) PageOrder.push('dot')
      PageOrder.push('ready')
      StateEngine('link')
      // StateEngine("next"); It was before when there were in-game instructions
    } else {
      if (window.Meta.get('Legacy')) {
        window.Meta.SubDo(LegacyWrapper, ResultsReturn)
      } else {
        window.Meta.SubDo(window.Meta.get('ENG'), ResultsReturn)
      }
    }

    function ResultsReturn(ret) {
      window.Meta.Return(ret)
    }
  },
}

const LegacyWrapper = {
  Start: function () {
    window.Meta.LegacyDo(window.Meta.get('ENG'))
  },
}
