import React from "react";
import {  Button, Checkbox, Grid, Stack } from "@mui/material";
import { LaunchDistance } from "../../scripts/GameEngines";
import placeh1 from "../../Images/gifs/distance-calibration-Side.gif";
import placeh2 from "../../Images/gifs/distance-calibration-POV.gif";
import Navigation from "../navigations/Navigation";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import ButtonNavigation from "../ButtonNavigation";
import TypographyTitle from "../TypographyTitle";
import DoubleColumnInstruction from "../layout/DoubleColumnInstruction";
import TypographyBody from "../TypographyBody";

function Step11({
  handleNext,
  handlePrev,
  setDistanceCompleted = () => {},
  setDistance = () => {},
}) {
  const handleDistance = () => {
    LaunchDistance(setDistanceCompleted, setDistance);
    setTimeout(handleNext, 500); // make sure the black screen appear first
  };
  return (
    <MainLayout 
      headerContent={
        <TypographyTitle textAlign="center" ignoreNarrow>
          When you’re at the correct distance the purple dot will dissapear.
        </TypographyTitle>
      }
      bodyContent={
          <DoubleColumnInstruction
            instruction1={
              <Stack spacing="8px">
                <TypographyBody textAlign="center" ignoreNarrow>
                  Step 1
                </TypographyBody>
                <TypographyBody ignoreNarrow>
                  Cover the indicated eye with your hand.            
                </TypographyBody>
              </Stack>
            }
            instruction2={
              <Stack spacing="8px">
                <TypographyBody textAlign="center" ignoreNarrow>
                  Step 2
                </TypographyBody>
                <TypographyBody ignoreNarrow>
                          Staring at the white dot, SLOWLY move your head close or
                          farther from the screen until one of the purple dots dissapears.              
                </TypographyBody>
              </Stack>

            }
            imageSrc1={placeh1}
            imageSrc2={placeh2}
          />
      }
      footerContent={
        <ButtonNavigation width="250px" onClick={handleDistance}>
          START PRACTICE
        </ButtonNavigation>
      }
    />
  );
}

export default Step11;
