import React from "react";
import { Button, Grid, Stack } from "@mui/material";
import screenSize2 from "../../Images/gifs/Screen-sizeGif.gif";
import { LaunchCali } from "../../scripts/GameEngines";
import Navigation from "../navigations/Navigation";
import MainLayout from "../layout/MainLayout";
import TextBoxAndImage from "../layout/TextBoxAndImage";
import ButtonNavigation from "../ButtonNavigation";
import TypographyTitle from "../TypographyTitle";
import ListBox from "../ListBox";
import ListBoxItem from "../ListBoxItem";
import useLayoutChange from "../../hooks/useLayoutChange";

function Step9({ handleNext, handlePrev }) {

  const theSize = useLayoutChange();
  let imageSpacing = "20px";
  // if (theSize == "2xl") imageSpacing = "200px";
  // else if (theSize == "xl") imageSpacing = "150px";
  // else if (theSize == "lg") imageSpacing = "100px";
  // else if (theSize == "md") imageSpacing = "100px";

  const stepsCB = (completed) => {
    if (completed) {
      handleNext();
    } else {
    }
  };

  const handleButton = () => {
    LaunchCali(stepsCB);
  };
  return (
    <MainLayout 
      bodyContentHeight="70%"
      bodyContent={
        <TextBoxAndImage
          boxContent={
            <Stack spacing="25px">
              <TypographyTitle>
                Use an ID or Credit Card to get your screen size.
              </TypographyTitle>
              <ListBox>
                <ListBoxItem>
                  Click Next and follow the instructions on the next page.
                </ListBoxItem>
              </ListBox>
            </Stack>
          }
          spacing={imageSpacing}
          imageAlt="Configuring Card on screen Image"
          imageSrc={screenSize2}
        
        />
      }
      footerContent={
        <ButtonNavigation onClick={handleButton}>
          NEXT
        </ButtonNavigation>
      }
    />
  );
}

export default Step9;
