import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import MainLayout from "../components/layout/MainLayout";
import { bodyTextSX, headerTextSX } from "../utils/constants";
import useLayoutChange from "../hooks/useLayoutChange";
import TypographyTitle from "../components/TypographyTitle";
import TypographyBody from "../components/TypographyBody";
import ButtonNavigation from "../components/ButtonNavigation";
import TextAndImage from "../components/layout/TextAndImage";

const Welcome = ({ handleNext }) => {


  return (
    <MainLayout 
        headerContent={
            <img
                src="./Assets/VSL_Logo.svg"
                // className="lg:w-[272px] h-auto w-[180px] lg:h-[120.43px]"
                className="h-[100%] w-auto min-h-[80px] "
            ></img>
        }
        bodyContent={
            <TextAndImage imageSrc="./Assets/sideimg.png" imageAlt="Welcome Image" textComponent={
                <Stack justifyContent="center" spacing="20px" sx={{width: "100%", height: "100%"}}>
                    <TypographyTitle noWrap>Virtual Vision Screener</TypographyTitle>

                    <TypographyBody>
                        This screener will conduct multiple tests to assess the health of
                        your eyes and vision.                    
                    </TypographyBody>

                    <TypographyBody>
                        At the end of the screener:
                        <span className="block">
                        You’ll receive a report that can be shared with your eye care
                        professional for further steps. ie If you need to visit a doctor
                        for glasses or a concern about your vision.
                        </span>
                    </TypographyBody>
                    <TypographyBody>
                        {" "}
                        <strong>
                            <em>Requires a screen over 12 inches.</em>
                        </strong>
                    </TypographyBody>

                </Stack>
            } />
          
        }
        footerContent={
            <ButtonNavigation onClick={handleNext}>
                BEGIN
            </ButtonNavigation>
        }
    />
  );
};

export default Welcome;
