const { Meta, Cali, MetaVision, MetaRandom, Randex } = require("./metaengine");

const PIXI = window.pixi;
// const PIXI = window["pixi"]

//~ const Vision = {
	//~ Setup : function(meta){
		//~ this._Vision = {
			//~ ScreenSize: parseInt(Cali.ScreenSize),
			//~ PatientDistance: Cali.PatientDistance,
			//~ VaToArc : function(va, gapCount = 5){
				//~ const Arcmin = 1/va * gapCount;
				//~ return Arcmin * 60;
			//~ },
			//~ PdToArc : function(pd){
				//~ const DisplacementInRadians = Math.atan(pd/100);
				//~ return (180 / Math.PI) * DisplacementInRadians * 3600;
			//~ },
			//~ ArcToCm : function(arc){
				//~ const HalfArcInRadian = ((arc/2) / 3600) * (Math.PI / 180);
				//~ return Math.tan(HalfArcInRadian) * this.PatientDistance * 2;
			//~ },
			//~ CmToPix : function(cm){
				//~ //Random note, this is wrong when first going fullscreen if the console is open
				//~ const w = Meta.Stage.Width;
				//~ const h = Meta.Stage.Height;
				//~ const DiagonalInPixels = Math.sqrt((w * w) + (h * h));
				//~ return DiagonalInPixels / (this.ScreenSize*(w/Meta.Stage.Width)*2.54) * cm;
			//~ },
			//~ PixToCm : function(pix){
				//~ const w = Meta.Stage.Width;
				//~ const h = Meta.Stage.Height;
				//~ const DiagonalInPixels = Math.sqrt((w * w) + (h * h));
				//~ return pix / (DiagonalInPixels / (this.ScreenSize*(w/Meta.Stage.Width)*2.54));
			//~ },
			//~ CmToArc : function(cm){
				//~ const HalfArcInRadian = Math.atan((cm / 2) / this.PatientDistance);
				//~ return (HalfArcInRadian * 2) * (180 / Math.PI) * 3600;
			//~ },
			//~ ArcToPd : function(arc){
				//~ const DisplacementInRadians = (arc / 3600) * (Math.PI / 180);
				//~ return 100 * Math.tan(DisplacementInRadians);
			//~ },
			//~ BlindRad : function(){
				//~ return this.CmToPix(this.ArcToCm(1*3600));
			//~ },
		//~ }
	//~ }
//~ };

//~ const Keyboard = {
	//~ Setup : function(meta){
		//~ //Pixi keyboard
		//~ //https://github.com/kittykatattack/learningPixi#keyboard
		//~ function keyboard(value) {
			//~ let key = {};
			//~ key.value = value;
			//~ key.isDown = false;
			//~ key.isUp = true;
			//~ key.press = undefined;
			//~ key.release = undefined;
			//~ //The `downHandler`
			//~ key.downHandler = event => {
				//~ if (event.key === key.value) {
					//~ if (key.isUp && key.press) key.press();
					//~ key.isDown = true;
					//~ key.isUp = false;
					//~ event.preventDefault();
				//~ }
			//~ };

			//~ //The `upHandler`
			//~ key.upHandler = event => {
				//~ if (event.key === key.value) {
					//~ if (key.isDown && key.release) key.release();
					//~ key.isDown = false;
					//~ key.isUp = true;
					//~ event.preventDefault();
				//~ }
			//~ };

			//~ //Attach event listeners
			//~ const downListener = key.downHandler.bind(key);
			//~ const upListener = key.upHandler.bind(key);

			//~ window.addEventListener("keydown", downListener, false);
			//~ window.addEventListener("keyup", upListener, false);

			//~ // Detach event listeners
			//~ key.unsubscribe = () => {
				//~ window.removeEventListener("keydown", downListener);
				//~ window.removeEventListener("keyup", upListener);
			//~ };

			//~ return key;
		//~ };
		
		//~ this.StdIn = {
			//~ _direction : [],
			//~ _accept : [],
			//~ _fullSet : [],
			//~ _quickKeys : {},
			//~ Relinquish : () =>{
				//~ Object.values(this.StdIn._quickKeys).forEach(val => val.unsubscribe());
				//~ this.StdIn._quickKeys = {};
				
				//~ this.StdIn._direction.forEach((e)=>{
					//~ e.unsubscribe();
				//~ });
				//~ this.StdIn._direction.length = 0;
				
				//~ this.StdIn._accept.forEach((e)=>{
					//~ e.unsubscribe();
				//~ });
				//~ this.StdIn._accept.length = 0;
				
				//~ this.StdIn._fullSet.forEach((e)=>{
					//~ e.unsubscribe();
				//~ });
				//~ this.StdIn._fullSet.length = 0;
				
				//~ for (const [key, value] of Object.entries(this.StdIn._quickKeys)) {
					//~ value.unsubscribe();
				//~ }
				//~ this.StdIn._quickKeys = {};
			//~ },
			//~ QuickKey : (key)=>{
				//~ this.StdIn._quickKeys[key] = keyboard(key);
			//~ },
			//~ OnQuickKey : (key, foo)=>{
				//~ this.StdIn._quickKeys[key].press = foo;
			//~ },
			//~ RequestDirection : () =>{
				//~ //0 = Left
				//~ //1 = Right
				//~ //2 = Up
				//~ // 3 = Down
				//~ //4 = W
				//~ //5 = A
				//~ //6 = S
				//~ //7 = D
				//~ if (this.StdIn._direction.length == 0){
					//~ this.StdIn._direction.push(keyboard("ArrowLeft"));
					//~ this.StdIn._direction.push(keyboard("ArrowRight"));
					//~ this.StdIn._direction.push(keyboard("ArrowUp"));
					//~ this.StdIn._direction.push(keyboard("ArrowDown"));
					//~ this.StdIn._direction.push(keyboard("w"));
					//~ this.StdIn._direction.push(keyboard("a"));
					//~ this.StdIn._direction.push(keyboard("s"));
					//~ this.StdIn._direction.push(keyboard("d"));
					//~ //Need to add the capitol version of the letters, otherwise capslock turns off WASD
				//~ }
			//~ },
			//~ OnLeft : (foo) => {
				//~ this.StdIn._direction[0].press = foo;
				//~ this.StdIn._direction[5].press = foo;
			//~ },
			//~ OnRight : (foo) => {
				//~ this.StdIn._direction[1].press = foo;
				//~ this.StdIn._direction[7].press = foo;
			//~ },
			//~ OnUp : (foo) => {
				//~ this.StdIn._direction[2].press = foo;
				//~ this.StdIn._direction[4].press = foo;
			//~ },
			//~ OnDown : (foo) => {
				//~ this.StdIn._direction[3].press = foo;
				//~ this.StdIn._direction[6].press = foo;
			//~ },
			//~ Left : () => {
				//~ return this.StdIn._direction[0].isDown || this.StdIn._direction[5].isDown;
			//~ },
			//~ Right : () => {
				//~ return this.StdIn._direction[1].isDown || this.StdIn._direction[7].isDown;
			//~ },
			//~ Up : () => {
				//~ return this.StdIn._direction[2].isDown || this.StdIn._direction[4].isDown;
			//~ },
			//~ Down : () => {
				//~ return this.StdIn._direction[3].isDown || this.StdIn._direction[6].isDown;
			//~ },
			//~ RequestAccept : () =>{
				//~ if (this.StdIn._accept.length == 0){
					//~ this.StdIn._accept.push(keyboard(" "));
				//~ }
			//~ },
			//~ OnAccept : (foo) =>{
				//~ this.StdIn._accept[0].press = foo;
			//~ },
			//~ DoAccept : ()=>{
				//~ this.StdIn._accept[0].press();
			//~ },
			//~ RequestFullSet : () =>{
				//~ Array.from("qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890").forEach((e) => {
					//~ const key = keyboard(e);
					//~ key.press = this.StdIn.DoFullSet;
					//~ key.stdin = this.StdIn;
					//~ this.StdIn._fullSet.push(key);
				//~ });
				//~ const key = keyboard("Backspace");
				//~ key.press = this.StdIn.DoFullSet;
				//~ key.stdin = this.StdIn;
				//~ this.StdIn._fullSet.push(key);
			//~ },
			//~ DoFullSet : function(){
				//~ this.stdin.OnFullSet(this.value.toUpperCase());
			//~ },
			//~ OnFullSet : function(){},
		//~ };
	//~ },
	//~ Cleanup : function(meta){
		//~ this.StdIn.Relinquish();
	//~ }
//~ };

export const CoreUtils = {
	Clamp : function(val, min, max){
		return Math.min(Math.max(val, min), max);
	}
}

export const AX = {
	Setup : function(meta){
		this.AX = {
			total: 0,
			count: 0,
			Acc: function(val){
				this.total += val;
				this.count++;
			},
			val: function(){
				return this.total / this.count;
			}
		};
	},
}

export const AXLR = {
	Setup : function(meta){
		this.AXL = {
			total: 0,
			count: 0,
			Acc: function(val){
				this.total += val;
				this.count++;
			},
			val: function(){
				return this.total / this.count;
			}
		};
		this.AXR = {
			total: 0,
			count: 0,
			Acc: function(val){
				this.total += val;
				this.count++;
			},
			val: function(){
				return this.total / this.count;
			}
		};
	},
}

export const BX = {
	Setup : function(meta){
		this.BX = {
			total: 0,
			count: 0,
			Acc: function(val){
				this.total += val;
				this.count++;
			},
			val: function(){
				return this.total / this.count;
			}
		};
	},
}

export const Tracker = {
	Setup : function(meta){
		this.Tracker = {
			correct: 0,
			wrong: 0,
			Correct: function(){
				this.correct++;
			},
			Wrong: function(){
				this.wrong++;
			},
			Percent: function(){
				return this.correct / (this.correct + this.wrong);
			},
			val: function(){
				return [this.correct, this.wrong];
			},
			str: function(){
				return this.correct+"/"+(this.correct+this.wrong);
			},
		};
	},
};

export const TrackerLR = {
	Setup : function(meta){
		this.TrackerL = {
			correct: 0,
			wrong: 0,
			Correct: function(){
				this.correct++;
			},
			Wrong: function(){
				this.wrong++;
			},
			Percent: function(){
				return this.correct / (this.correct + this.wrong);
			},
			val: function(){
				return [this.correct, this.wrong];
			},
			str: function(){
				return this.correct+"/"+(this.correct+this.wrong);
			},
		};
		this.TrackerR = {
			correct: 0,
			wrong: 0,
			Correct: function(){
				this.correct++;
			},
			Wrong: function(){
				this.wrong++;
			},
			Percent: function(){
				return this.correct / (this.correct + this.wrong);
			},
			val: function(){
				return [this.correct, this.wrong];
			},
			str: function(){
				return this.correct+"/"+(this.correct+this.wrong);
			},
		};
	},
};

export const TrackerS = {
	Setup : function(meta){
		this.TrackerS = {
			mode: "C",
			sepAmnt: [0.1, 0.1],
			sep: [0, 0],
			strike: [0, 0],
			best : [0, 0],
			worst : [0, 0],
			flip : 0,
			Correct: function(){
				if (this.strike[this.flip] == 2){
					this.strike[this.flip]=0;
					this.worst[this.flip] = Math.max(this.sep[this.flip], this.worst[this.flip])
				}else{
					this.sep[this.flip] += this.sepAmnt[this.flip];
					this.best[this.flip] = Math.max(this.sep[this.flip], this.best[this.flip]);
				}
				if (this.mode == "J"){
					this.flip = (this.flip + 1) % 2;
				}
			},
			Wrong: function(doJ=true){
				if (this.strike[this.flip] > 0){
					this.sep[this.flip] = Math.max(this.sep[this.flip]-this.sepAmnt[this.flip], 0);
					this.strike[this.flip] = 2;
				}else{
					this.strike[this.flip] = 1;
				}
				if (this.mode == "J" && doJ){
					this.flip = (this.flip + 1) % 2;
				}
			},
			Mercy: function(){
				this.strike[this.flip] = 2;
				this.Wrong(false);
			},
			val: function(){
				const sep = window.MetaVision.CmToPix(window.MetaVision.ArcToCm(window.MetaVision.PdToArc(this.sep[this.flip])))/2;
				switch(this.mode){
				case "C":
					return [-sep, 0];
				case "D":
					return [sep, 0];
				case "J":
					//swap this for C or D first with jump
					if (this.flip == 0){
						return [sep, 0];
					}else{
						return [-sep, 0];
					}
				case "BU":
					return [0, -sep];
				case "BD":
					return [0, sep];
				}
			},
		};
	},
};

export const Reakt = {
	Setup : function(meta){
		this.Reakt_Timer = 0;
		this.Reakt_Mark = ()=>{
			const val = this.Reakt_Timer;
			this.Reakt_Timer = 0;
			return val;
		}
	},
	Tick : function(meta, delta){
		this.Reakt_Timer += delta/60;
	},
};

//~ const Kountdown = {
	//~ Setup : function(meta){
		//~ this.Kountdown_Timer = 0;
		//~ this.Kountdown_Timer_State = 0;
		//~ this.StartKountdown = (dur)=>{
			//~ this.Kountdown_Timer = dur;
			//~ this.Kountdown_Timer_State = 1;
		//~ };
		//~ this.Kountdown = ()=>{
			//~ var mins = Math.floor(this.Kountdown_Timer/60);
			//~ var secs = Math.floor(this.Kountdown_Timer - mins*60) + 1;
			//~ if (secs == 60){mins++; secs = 0;}
			//~ return (mins>9? mins : "0" + mins) + ":" + (secs>9? secs : "0" + secs);
		//~ }
	//~ },
	//~ Tick : function(meta, delta){
		//~ if (this.Kountdown_Timer_State == 1){
			//~ this.Kountdown_Timer -= delta/60;
			//~ if (this.Kountdown_Timer <= 0){
				//~ if (this.KountdownEnd != null){
					//~ if (Array.isArray(this.KountdownEnd)){
						//~ this.KountdownEnd.forEach((e)=>{e(meta)});
					//~ }else{
						//~ this.KountdownEnd(meta);
					//~ }
				//~ }
				//~ this.Kountdown_Timer_State = 0;
			//~ }
		//~ }
	//~ },
//~ };

export const VectorUtils = {
	Setup : function(meta){
		this.Vector = {
			Distance : (v1, v2) => {
				if (v1.length == 2 && v2.length == 2){
					return Math.hypot(v1[0]-v2[0], v1[1]-v2[1]);
				}else{
					return 0/0;
				}
			},
			Direction : (v1, v2) => {
				if (v1.length == 2 && v2.length == 2){
					const RoR = [v1[0]-v2[0], v1[1]-v2[1]];
					const mag = Math.hypot(RoR[0], RoR[1]);
					return [RoR[0]/mag, RoR[1]/mag];
				}else{
					return [0, 0];
				}
			},
		};
	},
};

//~ const RandomUtils = {
	//~ Setup : function(meta){
		//~ this.Random = {
			//~ Dex : (len) => {
				//~ return Math.floor(Math.random() * len);
			//~ },
			//~ Range : (min, max) =>{
				//~ return Math.random() * (max - min) + min;
			//~ },
			//~ LastCompass : -1,
			//~ Compass : function(){
				//~ var ret = this.Dex(4);
				//~ while (ret == this.LastCompass){
					//~ ret = this.Dex(4);
				//~ }
				//~ this.LastCompass = ret;
				//~ return ret;
			//~ },
			//~ Vector : function(spd, flag){
				//~ var ret;
				//~ switch(flag){
				//~ case "H":
					//~ ret = [this.Dex(2)*2 - 1, 0];
					//~ break;
				//~ case "V":
					//~ ret = [0, this.Dex(2)*2 - 1];
					//~ break;
				//~ case "D":
					//~ ret = [this.Dex(2)*2 - 1, this.Dex(2)*2 - 1];
					//~ break;
				//~ case "4":
					//~ if (this.Dex(2) == 0){
						//~ ret = [this.Dex(2)*2 - 1, 0];
					//~ }else{
						//~ ret = [0, this.Dex(2)*2 - 1];
					//~ }
					//~ break;
				//~ case "O":
					//~ ret = [Math.random()*2-1, Math.random()*2-1];
					//~ const mag = Math.hypot(ret[0], ret[1]);
					//~ ret[0] /= mag;
					//~ ret[1] /= mag;
					//~ break;
				//~ case "8":
				//~ default:
					//~ ret = [this.Dex(3) - 1, (this.Dex(3) - 1)];
					//~ while (ret[0] == 0 && ret[1] == 0){
						//~ ret = [this.Dex(3) - 1, (this.Dex(3) - 1)];
					//~ }
					//~ break;
				//~ }
				//~ ret[0] *= spd;
				//~ ret[1] *= spd;
				//~ return ret;
			//~ },
			//~ Color : function(){
				//~ const hexchars = '0123456789ABCDEF';
				//~ const L = hexchars.length;
				//~ var str = "#";
				//~ for ( var i = 0; i < 6; i++) {
					//~ str += hexchars.charAt(Math.floor(Math.random() * L));
				//~ }
				//~ return PIXI.utils.string2hex(str);
			//~ },
			//~ Char : function(mask, filter=''){
				//~ //TFT is a mask example {upper|lower|numbers}
				//~ var sampleSpace = "";
				//~ if(mask[0] == "T"){
					//~ sampleSpace += 'QWERTYUIOPASDFGHJKLZXCVBNM';
				//~ }
				//~ if(mask[1] == "T"){
					//~ sampleSpace += 'qwertyuiopasdfghjklzxcvbnm';
				//~ }
				//~ if(mask[2] == "T"){
					//~ sampleSpace += '1234567890';
				//~ }
				
				//~ const L = sampleSpace.length;
				//~ var ret = '';
				//~ do{
					//~ ret = sampleSpace.charAt(Math.floor(Math.random() * L));
				//~ }while (filter.indexOf(ret) > -1);
				//~ return ret;
			//~ }
		//~ };
	//~ },
//~ };

export const ImageBank = {
	ImageBank: {
		Banks: {
			animals: [
				"Bat", "Bear", "Crocodile", "Deer", "Dolphin", "Eagle", "Elephant",
				"Fox", "Frog", "Giraffe", "Gorilla", "Hedgehog", "Hippopotamus", "Kangaroo",
				"Leopard", "Lion", "Octopus", "Owl", "Penguin", "Raccoon", "Rhino", "Seahorse",
				"Seal", "Shark", "Squirrel", "Tiger", "Whale", "Wolf", "Zebra",
			],
			food: [
				"Hotdog", "Burger", "Chicken", "Chocolate", "Cupcake", "Donut", "Fish", "Fries", "Ice Cream", "Ketchup",
				"Lemonade", "Mustard", "Pizza", "Ramen", "Sandwich", "Soda", "Sushi", "Taco"
			],
			shapes: [
				"Diamond", "Heart", "Circle", "Square", "Star", "Triangle", "Cube",
			],
		},
		RandomGet : function(bank, count=1){
			const ret = [];
			const rolls = [];
			for (var x = 0; x < count; x++){
				var dex = MetaRandom.Dex(this.Banks[bank].length);
				while (rolls.includes(dex)){
					dex = MetaRandom.Dex(this.Banks[bank].length);
				}
				rolls.push(dex);
				const key = this.Banks[bank][dex];
				ret.push({name:key, path:"/img/banks/" + bank + "/" + key + ".png"});
			}
			return ret.length == 1 ? ret[0] : ret;
		},
	}
};

export const Raincheck = {
	Raincheck: function(dur, foo){
		const prom = new Promise(res => setTimeout(res, dur));
		prom.then(foo);
	}
}

export const Shuffle4 = {
	Setup : function(meta){
		this.Shuffle4 = {};
		this.Shuffle4.Shuffle = null;
		
		this.Shuffle4.GiveAnswer = (a)=>{
			if (this.Shuffle4.__4WayAnswer < 0) return;
			
			if(this.Shuffle4.__4WayAnswer == a){
				this.Shuffle4.__4WayCorrects++;
				if (this.Shuffle4.Correct) this.Shuffle4.Correct();
			}else{
				this.Shuffle4.__4WayWrongs++;
				if (this.Shuffle4.Wrong) this.Shuffle4.Wrong();
			}
			this.Shuffle4.__4WayAnswer = this.Shuffle4.Shuffle();
		}
		
		this.Shuffle4.__4WayCorrects = 0;
		this.Shuffle4.__4WayWrongs = 0;
		
		this.Shuffle4.Init = (keys = ["Up", "Right", "Down", "Left"])=>{
			window.Meta.KeyDown(keys[0], ()=>{
				if (this.Shuffle4.__4WayAnswer < 0) return;
				
				if(this.Shuffle4.__4WayAnswer == 0){
					this.Shuffle4.__4WayCorrects++;
					if (this.Shuffle4.Correct) this.Shuffle4.Correct();
				}else{
					this.Shuffle4.__4WayWrongs++;
					if (this.Shuffle4.Wrong) this.Shuffle4.Wrong();
				}
				this.Shuffle4.__4WayAnswer = this.Shuffle4.Shuffle();
			});
			
			window.Meta.KeyDown(keys[2], ()=>{
				if (this.Shuffle4.__4WayAnswer < 0) return;
				
				if(this.Shuffle4.__4WayAnswer == 2){
					this.Shuffle4.__4WayCorrects++;
					if (this.Shuffle4.Correct) this.Shuffle4.Correct();
				}else{
					this.Shuffle4.__4WayWrongs++;
					if (this.Shuffle4.Wrong) this.Shuffle4.Wrong();
				}
				this.Shuffle4.__4WayAnswer = this.Shuffle4.Shuffle();
			});
			
			window.Meta.KeyDown(keys[3], ()=>{
				if (this.Shuffle4.__4WayAnswer < 0) return;
				
				if(this.Shuffle4.__4WayAnswer == 3){
					this.Shuffle4.__4WayCorrects++;
					if (this.Shuffle4.Correct) this.Shuffle4.Correct();
				}else{
					this.Shuffle4.__4WayWrongs++;
					if (this.Shuffle4.Wrong) this.Shuffle4.Wrong();
				}
				this.Shuffle4.__4WayAnswer = this.Shuffle4.Shuffle();
			});
			
			window.Meta.KeyDown(keys[1], ()=>{
				if (this.Shuffle4.__4WayAnswer < 0) return;
				
				if(this.Shuffle4.__4WayAnswer == 1){
					this.Shuffle4.__4WayCorrects++;
					if (this.Shuffle4.Correct) this.Shuffle4.Correct();
				}else{
					this.Shuffle4.__4WayWrongs++;
					if (this.Shuffle4.Wrong) this.Shuffle4.Wrong();
				}
				this.Shuffle4.__4WayAnswer = this.Shuffle4.Shuffle();
			});
		
			this.Shuffle4.__4WayAnswer = this.Shuffle4.Shuffle();
		}
	},
};

export const ShuffleClick = {
	Setup : function(meta){
		this.ShuffleClick = {};
		this.ShuffleClick.Shuffle = null;
		this.ShuffleClick.PostShuffle = null;
		
		this.ShuffleClick.Init = (arr)=>{
			arr.forEach((e)=>{
				e.interactive = true;
				e.on('pointerdown', ()=>{
					if (e.IsAns){
						if (this.ShuffleClick.Correct) this.ShuffleClick.Correct();
					}else{
						if (this.ShuffleClick.Wrong) this.ShuffleClick.Wrong();
					}
					this.ShuffleClick.DoShuffle(this.ShuffleClick.ARR);
				});
			});
			this.ShuffleClick.ARR = arr;
			this.ShuffleClick.DoShuffle(this.ShuffleClick.ARR);
			if (this.ShuffleClick.PostShuffle) this.ShuffleClick.PostShuffle();
		}
		
		this.ShuffleClick.DoShuffle = (ARR)=>{
			const CurAnswer = MetaRandom.Dex(ARR.length);
			for(var x = 0; x < ARR.length; x++){
				ARR[x].IsAns = x == CurAnswer;
				this.ShuffleClick.Shuffle(ARR[x], CurAnswer);
			}
		};
	},
};

export const VT1 = {
	Setup: function(meta){
		this.VtUi = new PIXI.Container();
		this.Stage = new PIXI.Container();
		this.VtUi.addChild(this.Stage);
		this.Stage.__meta__ = meta;
		
		this.TopBar = new PIXI.Container();
		this.VtUi.addChild(this.TopBar);
		const TopBarHeight = 50;
		this.TopBar.bg = new PIXI.Graphics();
		this.TopBar.addChild(this.TopBar.bg);
		this.TopBar.scoreText = new PIXI.Text("Score");
		this.TopBar.addChild(this.TopBar.scoreText);
		this.TopBar.timeText = new PIXI.Text("Time");
		this.TopBar.addChild(this.TopBar.timeText);
		this.Stage.TopBar = this.TopBar;
		
		const TimeMarginRight = 50;
		this.TopBar.DrawWidth = function(w){
			this.bg.clear();
			this.bg.beginFill(0xcccccc).drawRect(0,0,w,TopBarHeight);
			this.scoreText.y = TopBarHeight/2 - this.scoreText.height/2;
			this.scoreText.x = w/2 - this.scoreText.width/2;
			this.timeText.y = TopBarHeight/2 - this.timeText.height/2;
			this.timeText.x = w - this.timeText.width - TimeMarginRight;
		}
		
		Object.defineProperty(this.TopBar, "TextM", {
			set : function(s) {
				this.scoreText.text = s;
				this.scoreText.x = this.bg.width/2 - this.scoreText.width/2;
			},
		});
		Object.defineProperty(this.TopBar, "TextR", {
			set : function(t) {
				this.timeText.text = t;
				this.timeText.x = this.bg.width - this.timeText.width - TimeMarginRight;
			},
		});
		
		this.Stage.y = TopBarHeight;
		
		Object.defineProperty(this.Stage, "top", {
			get : function() {return this.y},
		});
		Object.defineProperty(this.Stage, "bot", {
			get : function() {return this.__meta__.Screen.height;},
		});
		Object.defineProperty(this.Stage, "left", {
			get : function() {return 0},
		});
		Object.defineProperty(this.Stage, "right", {
			get : function() {return this.__meta__.Screen.width - this.x;},
		});
		Object.defineProperty(this.Stage, "midX", {
			get : function() {return (this.__meta__.Screen.width - this.x)/2;},
		});
		Object.defineProperty(this.Stage, "midY", {
			get : function() {return (this.__meta__.Screen.height - this.TopBar.height)/2 + this.TopBar.height;},
		});
		
		this.Score = 0;
		this.ScoreUp = function(val){
			this.Score += Math.round(val);
		}
		this.ScoreDown = function(val){
			this.Score = Math.max(0, this.Score - Math.round(val));
		}

		this.ScoreR = 0;
		this.ScoreUpR = function(val){
			this.ScoreR += Math.round(val);
		}
		this.ScoreDownR = function(val){
			this.ScoreR = Math.max(0, this.ScoreR - Math.round(val));
		}

		this.ScoreL = 0;
		this.ScoreUpL = function(val){
			this.ScoreL += Math.round(val);
		}
		this.ScoreDownL = function(val){
			this.ScoreL = Math.max(0, this.ScoreL - Math.round(val));
		}
		
		this.Perc = function(val){
			return Math.round(val * 100) + "%";
		}
		this.Round = function(f, n){
			const pow = 10 ** n;
			return Math.round(f * pow) / pow;
		}
	},
	Resize : function(meta){
		this.TopBar.DrawWidth(meta.Screen.width);
	}
}

export const VT2 = {
	Setup: function(meta){
		this.VtUi = new window.PIXI.Container();
		this.Stage = new window.PIXI.Container();
		this.Stage.__meta__ = meta;
		
		this.TopBar = new window.PIXI.Container();
		this.VtUi.addChild(this.TopBar);
		this.TopBar.scoreText = new window.PIXI.Text("Score");
		this.TopBar.addChild(this.TopBar.scoreText);
		this.TopBar.timeText = new window.PIXI.Text("Time");
		this.TopBar.addChild(this.TopBar.timeText);
		this.Stage.TopBar = this.TopBar;
		this.TopBar.Stage = this.Stage;
		
		switch(window.Meta.get("ContrastMode")){
		case "BoW":
			this.TopBar.scoreText.style.fill = 0x000000;
			this.TopBar.timeText.style.fill = 0x000000;
			break;
		case "BoG":
			this.TopBar.scoreText.style.fill = 0x000000;
			this.TopBar.timeText.style.fill = 0x000000;
			break;
		case "WoB":
			this.TopBar.scoreText.style.fill = 0xffffff;
			this.TopBar.timeText.style.fill = 0xffffff;
			break;
		default:
			this.TopBar.scoreText.style.fill = 0xffffff;
			this.TopBar.timeText.style.fill = 0xffffff;
			break;
		}
		
		const BotMargin = 10;
		const SideMargin = 25;
		this.TopBar.DrawWidth = function(w){
			this._W = w;
			this.scoreText.y = this.Stage.__meta__.Screen.height - this.scoreText.height - BotMargin;
			this.scoreText.x = SideMargin;
			this.timeText.y = this.Stage.__meta__.Screen.height - this.timeText.height - BotMargin;
			this.timeText.x = w - this.timeText.width - SideMargin;
		}
		
		Object.defineProperty(this.TopBar, "TextM", {
			set : function(s) {
				this.scoreText.text = s;
			},
		});
		Object.defineProperty(this.TopBar, "TextR", {
			set : function(t) {
				this.timeText.text = t;
				this.timeText.x = this._W - this.timeText.width - SideMargin;
			},
		});
		
		Object.defineProperty(this.Stage, "top", {
			get : function() {return this.y},
		});
		Object.defineProperty(this.Stage, "bot", {
			get : function() {return this.__meta__.Screen.height;},
		});
		Object.defineProperty(this.Stage, "left", {
			get : function() {return 0},
		});
		Object.defineProperty(this.Stage, "right", {
			get : function() {return this.__meta__.Screen.width;},
		});
		Object.defineProperty(this.Stage, "midX", {
			get : function() {return (this.__meta__.Screen.width)/2;},
		});
		Object.defineProperty(this.Stage, "midY", {
			get : function() {return (this.__meta__.Screen.height)/2;},
		});
		
		this.Score = 0;
		this.ScoreUp = function(val){
			this.Score += Math.round(val);
		}
		this.ScoreDown = function(val){
			this.Score = Math.max(0, this.Score - Math.round(val));
		}

		this.ScoreL = 0;
		this.ScoreUpL = function(val){
			this.ScoreL += Math.round(val);
		}
		this.ScoreDownL = function(val){
			this.ScoreL = Math.max(0, this.ScoreL - Math.round(val));
		}

		this.ScoreR = 0;
		this.ScoreUpR = function(val){
			this.ScoreR += Math.round(val);
		}
		this.ScoreDownR = function(val){
			this.ScoreR = Math.max(0, this.ScoreR - Math.round(val));
		}
		
		
		
		this.Perc = function(val){
			return Math.round(val * 100) + "%";
		}
		this.Round = function(f, n){
			const pow = 10 ** n;
			return Math.round(f * pow) / pow;
		}
	},
	Resize : function(meta){
		this.TopBar.DrawWidth(meta.Screen.width);
	}
}

//~ const E2 = {
	//~ Start : function(meta){
		//~ StdIn.RequestAccept();
		//~ StdIn.OnAccept(()=>{
			//~ console.log(this.Reakt_Mark());
		//~ });
		
		//~ this.TestEvent = ()=>{console.log("gottem")};
	//~ },
//~ };

export const Watcher = {
	Start : function(meta){
		this.Watch_Targets = [];
		this.Watch = (e)=>{
			this.Watch_Targets.push(e);
			e.OnDeath.push((e)=>{
				this.Watch_Targets.splice(this.Watch_Targets.indexOf(e), 1);
			});
		}
	},
	Tick : function(meta){
		//console.log(this.Watch_Targets);
	},
}

export const SizeWatcher = {
	Start : function(meta){
		this.WatchSprite = (o, sprite)=>{
			o._WatchedSprite = sprite
			// e.OnDeath.push((e)=>{
			// 	this.SpriteWatch_Targets.splice(this.SpriteWatch_Targets.indexOf(e), 1);
			// });
		}
	},
}

export const Kreators = {
	Setup : function(meta){
		this.Kreator_List = [];
		this.AddKreator = (k)=>{
			this.Kreator_List.push(k);
			k.S = new window.PIXI.Graphics();
			if (k.Kreate != null) k.Kreate(k.S);
			k.OnDeath.push((k)=>{
				k.S.parent.removeChild(k.S);
				this.Kreator_List.splice(this.Kreator_List.indexOf(k), 1);
			});
		}
	},
	Resize : function(meta){
		this.Kreator_List.forEach((e)=>{
			if (e.Kreate != null) {
				e.S.clear();
				e.Kreate(e.S);
			}
		});
	},
};

export const Spritors = {
	Setup : function(meta){
		this.Spritor_List = [];
		this.AddSpritor = (t, o)=>{
			this.Spritor_List.push(o);
			o.S = new PIXI.Sprite(t);
			o.S.anchor.set(0.5);
			if (o.Redraw != null) o.Redraw(o.S);
			this.Stage.addChild(o.S); 
			o.OnDeath.push((o)=>{
				o.S.parent.removeChild(o.S);
				this.Spritor_List.splice(this.Spritor_List.indexOf(o), 1);
			});
		}
	},
	Resize : function(meta){
		this.Spritor_List.forEach((e)=>{
			if (e.Redraw != null) {
				e.Redraw(e.S);
			}
		});
	},
};

export const GridMaster = {
	Setup : function(meta){
		this.Grid_List = [];
		this.Grid_Spacing = 50;
		this.AddToGrid = (g)=>{
			this.Grid_List.push(g);
			g.Grid = this;
			g.OnDeath.push((g)=>{
				// this.Grid_List.splice(this.Grid_List.indexOf(k), 1);
				this.Grid_List.splice(this.Grid_List.indexOf(g), 1);
			});
			this.Recalculate_Grid();
		};
		this.Recalculate_Grid = ()=>{
			this.Grid_List.forEach((e, dex)=>{
				e.S.x = this.GridOriginX - e.S.width/2 + (dex - (this.Grid_List.length-1)/2)*(e.S.width + this.Grid_Spacing);
				e.S.y = this.GridOriginY - e.S.height/2;
			});
		};
	},
	Resize : function(meta){
		if (this.GridResize) this.GridResize();
		this.Recalculate_Grid();
	},
};

export const GridWatcher = {
	_NewGrid: function(){
		return {
			GridOriginX : 0,
			GridOriginY : 0,
			ColumnCount : 4,
			GridWidth : 10,
			GridHeight : 10,
			GridSpacingX : 0,
			GridSpacingY : 0,
			CellOrigin: "TopLeft",
			Render : function(arr){
				arr.forEach((e, dex)=>{
					const CornerX1 = this.GridOriginX - this.GridWidth/2 + ((dex%this.ColumnCount) - (this.ColumnCount-1)/2)*(this.GridWidth + this.GridSpacingX);
					const CornerX2 = CornerX1 + this.GridWidth;
					
					const CornerY1 = this.GridOriginY - this.GridHeight/2 + ((Math.floor(dex/this.ColumnCount)) - (Math.floor(arr.length/this.ColumnCount)-1)/2)*(this.GridHeight + this.GridSpacingY);
					const CornerY2 = CornerY1 + this.GridHeight;
					if (this.CellOrigin == "Center"){
						e.x = (CornerX1 + CornerX2)/2;
						e.y = (CornerY1 + CornerY2)/2;
					}else{
						e.x = (CornerX1 + CornerX2)/2 - e.width/2;
						e.y = (CornerY1 + CornerY2)/2 - e.height/2;
					}
				});
			}
		}
	}
}

export const RDS = {
	Load : [
		["rds_f", "randot3.frag"],
	],
	Setup : function(meta){
		const RDS = new window.PIXI.Graphics().beginFill(0x00ff00).drawRect(0,0, 0, 0);
		const RDSF = new window.PIXI.Filter(null, `precision mediump float;

		varying vec2 vTextureCoord;
		varying vec4 vColor;
		
		uniform sampler2D uSampler;
		uniform vec2 _DIM;
		
		uniform float depthShift;
		uniform vec2 separation;
		uniform float randotWidth;
		uniform float randotHeight;
		uniform float pixelHeight;
		uniform float pixelWidth;
		uniform float pixelSize;
		uniform float TIME;
		uniform float SEED;
		//uniform bool AllowSubpixels = false;
		
		float SampleRandom3(vec2 uv){
			//float2 pScale = float2(_PixelSize, _PixelSize) / _ScreenParams;
			vec2 scaledUV = uv - mod(uv + vec2(TIME, TIME)* 0.0001, vec2(pixelWidth, pixelHeight));
			//scaledUV +=  (_Time.yy * _Framerate + _PhaseShift) * 0.000001;
			float val;
			val = fract(sin(dot(scaledUV, vec2(64.3325,25.4976))*scaledUV.y*10.0)*438.5453123);
			//return val;
			return (val >= 0.5 ? 1.0 : 0.0);
		}
		
		float SampleRandom4(vec2 pix){
			vec2 uv = floor(pix / vec2(pixelSize, pixelSize)) / _DIM;
			float val;
			val = fract(sin(dot(uv, vec2(64.3325,25.4976))*uv.y*10.0)*438.5453123);
			//return val;
			return (val >= 0.5 ? 1.0 : 0.0);
		}
		
		float SampleRandom5(vec2 pix){
			vec2 uv = floor(pix / vec2(pixelSize, pixelSize)) / _DIM;
			float val;
			val = fract(sin(dot(uv + vec2(1, 1), vec2(64.3325,25.4976))*uv.y*10.0)*SEED);
			//return val;
			return (val >= 0.5 ? 1.0 : 0.0);
		}
		
		float FinalRed = 0.0;
		float FinalBlue = 0.0;
		
		void main(void)
		{
			vec2 pixCoord = gl_FragCoord.xy - vec2(0.5, 0.5);
			pixCoord.y = _DIM.y - pixCoord.y;
			//~ gl_FragColor = vec4(pixCoord.xy/_DIM, 0, 1);
			//~ return;
			
			vec2 unshiftLeft = pixCoord - separation;
			vec2 unshiftRight = pixCoord + separation;
			
			float LEFT = _DIM.x/2.0 - randotWidth/2.0;
			float RIGHT = _DIM.x/2.0 + randotWidth/2.0;
			float TOP = _DIM.y/2.0 - randotHeight/2.0;
			float BOTTOM = _DIM.y/2.0 + randotHeight/2.0;
			
			//~ gl_FragColor = texture2D(uSampler, vTextureCoord);
			//~ return;
			//something kind of cool happened when you switch FinalBlue and FinalRed being set equal, as well as the +/- on the vec2
			if (unshiftLeft.x >= LEFT && unshiftLeft.x <= RIGHT && unshiftLeft.y >= TOP && unshiftLeft.y <= BOTTOM){
				vec2 unshift = unshiftLeft - vec2(depthShift, 0);
				unshift -= mod(unshift, vec2(pixelSize, pixelSize));
				float depth = texture2D(uSampler, unshift/_DIM).x;
				if (depth > 0.0){
					FinalBlue = SampleRandom5(unshiftLeft - vec2(depthShift * depth, 0));
				}else{
					FinalBlue = SampleRandom5(unshiftLeft);
				}
			}
			if (unshiftRight.x >= LEFT && unshiftRight.x <= RIGHT && unshiftRight.y >= TOP && unshiftRight.y <= BOTTOM){
				vec2 unshift = unshiftRight + vec2(depthShift, 0);
				unshift -= mod(unshift, vec2(pixelSize, pixelSize));
				float depth = texture2D(uSampler, unshift/_DIM).x;
				if (depth > 0.0){
					FinalRed = SampleRandom5(unshiftRight + vec2(depthShift * depth, 0));
				}else{
					FinalRed = SampleRandom5(unshiftRight);
				}
			}
			
			//~ gl_FragColor = vec4(FinalRed * 0.32, 0, FinalBlue * 0.363, 1);
			gl_FragColor = vec4(FinalRed, 0, FinalBlue, 1);
		}`, {
			separation: new window.PIXI.Point(0, 0),
			depthShift: 10,
			pixelSize: 1,
			SEED: 438.5453123,
		});
		RDS.filters = [RDSF];
		
		Object.defineProperty(RDS, "RDIM", {
			get : function(){
				return this._RanDim;
			},
			set : function(d) {
				this._RanDim = d;
				this.filters[0].uniforms.randotWidth = d;
				this.filters[0].uniforms.randotHeight = d;
			},
		});
		Object.defineProperty(RDS, "PixelSize", {
			set : function(p) {
				if (p == null) p = 3;
				this._PSize = p;
				this.filters[0].uniforms.pixelSize = p;
				this.filters[0].uniforms.pixelWidth = p;
				this.filters[0].uniforms.pixelHeight = p;
			},
		});
		Object.defineProperty(RDS, "Separation", {
			set : function(s) {
				this._Sep = s;
				//these are backwards until the shader gets color settings
				this.filters[0].uniforms.separation.x = -s[0];
				this.filters[0].uniforms.separation.y = -s[1];
			},
		});
		Object.defineProperty(RDS, "Depth", {
			set : function(d) {
				this._Depth = d;
				this.filters[0].uniforms.depthShift = d;
			},
		});
		RDS.RDS_LastSeed = 0;
		RDS.ReSeed = function(){
			const seeds = [432.56495323265, 501.2326564123, 395.3369448, 488.2277996];
			this.RDS_LastSeed = (this.RDS_LastSeed + 1) % seeds.length;
			this.filters[0].uniforms.SEED = seeds[this.RDS_LastSeed];
		};
		
		this.RDS = RDS;
	},
	Resize : function(meta){
		this.RDS.clear();
		this.RDS.beginFill(0x00ff00).drawRect(0,0, meta.Screen.width, meta.Screen.height);
		this.RDS.filters[0].uniforms._DIM = new window.PIXI.Point(meta.Screen.width, meta.Screen.height);
		//this.RDIM= this._RanDim;
		//this.PixelSize = this._PSize;
	},
}

export const GABOR = {
	Load : [
		["gabor_f", "/res/Gabor.frag"],
	],
	Setup : function(meta){
		const GABOR = new PIXI.Graphics().beginFill(0x00ff00).drawRect(0,0, 1, 1);
		const GABORF = new PIXI.Filter(null, this.gabor_f, {
			_DIM: 1,
			Orientation: 0,
			Wavelength: 100,
			StdDev: 30,
			Phase: 0,
		});
		GABOR.filters = [GABORF];
		
		Object.defineProperty(GABOR, "DIM", {
			set : function(d) {
				this.filters[0].uniforms._DIM = d;
				this.width = d;
				this.height = d;
			},
		});
		
		Object.defineProperty(GABOR, "Shift", {
			set : function(s) {
				this.filters[0].uniforms.Shift = s;
			},
		});
		
		Object.defineProperty(GABOR, "Wavelength", {
			set : function(w) {
				this.filters[0].uniforms.Wavelength = w;
			},
		});
		
		Object.defineProperty(GABOR, "Orientation", {
			set : function(o) {
				this.filters[0].uniforms.Orientation = o;
			},
		});
		
		this.GABOR = GABOR;
	},
}

//Old GVS Transition Screens

//~ const Transition_Engine = Meta.Combine(Kountdown, {
	//~ Load : [
		//~ ["Check", "/img/roundSquareCheck.png"],
	//~ ],
	//~ Start : function(meta){
		//~ const curApp = meta.PixiApp;
		//~ meta.backgroundColor = this.bgc;
		//~ this.StartKountdown(1.5);
		
		//~ const text = new PIXI.Text(this.Message,{fontFamily : 'Arial', fontSize: 24, fill : this.texttint, align : 'center', fontWeight: 'bold'});
		//~ text.x = curApp.screen.width/2 - text.width/2;
		//~ text.y = curApp.screen.height/2 - text.height/2;
		//~ curApp.stage.addChild(text);
		
		//~ const check = new PIXI.Sprite.from(this.Check);
		//~ check.anchor.set(1, 0.5);
		//~ check.x = text.x - 40;
		//~ check.y = text.y + text.height/2;
		//~ check.height = 75;
		//~ check.width = 75;
		//~ check.tint = this.checktint;
		//~ curApp.stage.addChild(check);
	//~ },
	//~ KountdownEnd : function(meta){
		//~ if (this.Next){
			//~ meta.Do(this.Next);
		//~ }else{
			//~ CompleteTest();
		//~ }
	//~ }
//~ });

//~ const Transition_Dark = Meta.Combine(Transition_Engine, {
	//~ Setup : function(meta){
		//~ this.bgc = 0x000000;
		//~ this.checktint = 0x6CBE45;
		//~ this.texttint = 0xffffff;
	//~ }
//~ });

//~ const Transition_Light = Meta.Combine(Transition_Engine, {
	//~ Setup : function(meta){
		//~ this.bgc = 0xffffff;
		//~ this.checktint = 0x000000;
		//~ this.texttint = 0x000000;
	//~ }
//~ });

export function PixiStyleStd(obj){
	Object.defineProperty(obj, "x", {
		get : function () {
			return this._x;
		},
		set : function (val) {
			this._x = val;
			this.PixiObj.x = this._x/this._meta.ZoomX;
		},
	});
	
	Object.defineProperty(obj, "y", {
		get : function () {
			return this._y;
		},
		set : function (val) {
			this._y = val;
			this.PixiObj.y = this._y/this._meta.ZoomY;
		},
	});
	
	Object.defineProperty(obj, "onclick", {
		set : function (foo) {
			this.PixiObj.interactive = true;
			this.PixiObj.buttonMode = true;
			this.PixiObj.on('pointerdown', foo);
		},
	});
};

//~ const AntiZoom = {
	//~ Setup : function(meta){
		//~ this.AntiZoom = {
			//~ __meta__: meta,
			//~ __children__: [],
			//~ NewRect : function(x, y, width, height, config={}){
				//~ const PixiObj = new PIXI.Graphics();
				//~ if (config.fill){
					//~ PixiObj.beginFill(config.fill);
				//~ }
				//~ if (config.stroke && config.line){
					//~ PixiObj.lineStyle(config.stroke, config.line);
				//~ }
				//~ PixiObj.drawRect(0, 0, 1, 1);
				
				//~ const RET = {
					//~ _meta: this.__meta__,
					//~ PixiObj,
					//~ _x : x,
					//~ _y: y,
					//~ _width: width,
					//~ _height: height,
					//~ Bye : function(){
						//~ this.PixiObj.parent.removeChild(this.PixiObj);
					//~ },
					//~ Calc : function(){
						//~ this.x = this._x;
						//~ this.y = this._y;
						//~ this.width = this._width;
						//~ this.height = this._height;
					//~ }
				//~ }
				
				//~ Object.defineProperty(RET, "width", {
					//~ get : function () {
						//~ return this._width;
					//~ },
					//~ set : function (val) {
						//~ this._width = val;
						//~ this.PixiObj.width = this._width/this._meta.ZoomX;
					//~ },
				//~ });
				
				//~ Object.defineProperty(RET, "height", {
					//~ get : function () {
						//~ return this._height;
					//~ },
					//~ set : function (val) {
						//~ this._height = val;
						//~ this.PixiObj.height = this._height/this._meta.ZoomY;
					//~ },
				//~ });
				
				//~ PixiStyleStd(RET);
				//~ RET.Calc();
				//~ this.__meta__.PixiApp.stage.addChild(PixiObj);
				//~ this.__children__.push(RET);
				
				//~ return RET;
			//~ },
			//~ NewText : function(txt, config={}){
				//~ const fontSize = config.size ? config.size : 24;
				//~ const PixiObj = new PIXI.Text(txt, {fontFamily : 'Arial', fontSize: 24, fill : (config.fill?config.fill:0xffffff), align : 'center', fontWeight: 'bold'});
				
				//~ const RET = {
					//~ _meta: this.__meta__,
					//~ PixiObj,
					//~ _x : 0,
					//~ _y: 0,
					//~ _font: fontSize,
					//~ Bye : function(){
						//~ this.PixiObj.parent.removeChild(this.PixiObj);
					//~ },
					//~ Calc : function(){
						//~ this.x = this._x;
						//~ this.y = this._y;
						//~ this.fontSize = this._font;
					//~ }
				//~ }
				
				//~ PixiStyleStd(RET);
				
				//~ Object.defineProperty(RET, "width", {
					//~ get : function () {
						//~ return this.PixiObj.width * this._meta.ZoomX;
					//~ },
				//~ });
				
				//~ Object.defineProperty(RET, "height", {
					//~ get : function () {
						//~ return this.PixiObj.height * this._meta.ZoomY;
					//~ },
				//~ });
				
				//~ Object.defineProperty(RET, "fontSize", {
					//~ get : function () {
						//~ return this._font;
					//~ },
					//~ set : function (val) {
						//~ this._font = val;
						//~ this.PixiObj.style.fontSize = this._font/this._meta.ZoomY;
					//~ },
				//~ });
				
				//~ RET.Calc();
				//~ this.__meta__.PixiApp.stage.addChild(PixiObj);
				//~ this.__children__.push(RET);
				
				//~ return RET;
			//~ },
		//~ };
	//~ },
	//~ Resize: function(meta){
		//~ this.AntiZoom.__children__.forEach((e)=>{
			//~ e.Calc();
		//~ });
	//~ },
//~ };

export const SNOW = { //Simple Noise O??? W???
	Load : [
		["snow_f", "/res/SNOW.frag"],
	],
	Setup : function(meta){
		const SNOW = new PIXI.Graphics().beginFill(0x00ff00).drawRect(0,0, 0, 0);
		const SNOWF = new PIXI.Filter(null, this.snow_f, {
			Density: 0.5,
			SEED: 438.5453123,
		});
		SNOW.filters = [SNOWF];
		
		Object.defineProperty(SNOW, "Density", {
			get : function(){
				return this._Dens;
			},
			set : function(d) {
				this._Dens = d;
				this.filters[0].uniforms.Density = d;
			},
		});
		SNOW.SNOW_LastSeed = 0;
		SNOW.ReSeed = function(){
			const seeds = [432.56495323265, 501.2326564123, 395.3369448, 488.2277996];
			this.RDS_LastSeed = (this.SNOW_LastSeed + 1) % seeds.length;
			this.filters[0].uniforms.SEED = seeds[this.SNOW_LastSeed];
		};
		
		this.SNOW = SNOW;
	},
	Resize : function(meta){
		this.SNOW.clear();
		this.SNOW.beginFill(0x00ff00).drawRect(0,0, meta.Screen.width, meta.Screen.height);
		this.SNOW.filters[0].uniforms._DIM = new PIXI.Point(meta.Screen.width, meta.Screen.height);
	},
}

//Temporary home for some nice global functions that may change
export function NewLandoltE(c=false){
	const E = new PIXI.Graphics();
	E.Crowd = c ? true : false;
	
	Object.defineProperty(E, "size", {
		get : function() {
			return this.width;
		},
		set : function(s) {
			this.clear();
			
			if (s > 0){
				if (s < 5){
					this.fits = false;
					this.beginFill(0xff0000);
					this.drawRect(-s/2,-s/2,s,s);
				}else{
					this.fits = true;
					
					const fifth = Math.floor(s/5);
					const half = fifth * 2;
					const bar = fifth * 3;
					const whole = fifth * 5;
					const fudge = Math.round(fifth/2);
					
					this.beginFill(0xffffff);
					this.drawRect(-half - fudge,-half+fifth*4 - fudge,whole,fifth);
					this.drawRect(-half - fudge,-half - fudge,fifth,whole);
					this.drawRect(-half + fifth*2 - fudge,-half - fudge,fifth,whole);
					this.drawRect(-half + fifth*4 - fudge,-half - fudge,fifth,whole);
					
					if (this.Crowd){
						this.drawRect(-whole - fudge, -fifth - fudge, fifth, bar);
						this.drawRect(whole - fudge, -fifth - fudge, fifth, bar);
						this.drawRect(-fifth - fudge, -whole - fudge, bar, fifth);
						this.drawRect(-fifth - fudge, whole - fudge, bar, fifth);
					}
				}
			}
		},
	});
	
	Object.defineProperty(E, "Answer", {
		get : function() {
			return this._A;
		},
		set : function(d) {
			this._A = d;
			this.rotation = Math.PI/2 * d;
		},
	});
	
	return E;
}

/////LEGACY CALIBRATION PIXI/////

//~ const SSC_Engine = Meta.Combine(Vision, Keyboard, {
	//~ Start : function(meta){
		//~ console.log(meta);
		//~ meta.backgroundColor = 0x000000;
		//~ const Vision = this._Vision;
		//~ const curApp = meta.PixiApp;
		//~ const StdIn = this.StdIn;
		
		//~ const IdCard = new PIXI.Sprite.from("/img/id.png");
		//~ IdCard.anchor.set(0.5);
		//~ IdCard.x = curApp.screen.width/2;
		//~ IdCard.y = curApp.screen.height/2;
		//~ curApp.stage.addChild(IdCard);
		
		//~ var DRO = null;
		//~ PrintDist();
		
		//~ function PrintDist(){
			//~ Vision.ScreenSize = Cali.ScreenSize;
			
			//~ if (DRO != null){
				//~ DRO.parent.removeChild(DRO);
			//~ }
			//~ DRO = new PIXI.Text(Cali.ScreenSize + " IN",{fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'center', fontWeight: 'bold'});
			//~ DRO.x = curApp.screen.width/2 - DRO.width/2;
			//~ DRO.y = DRO.height;
			//~ curApp.stage.addChild(DRO);
			
			//~ //ISO ID1 Specs
			//~ IdCard.width = Vision.CmToPix(8.56);
			//~ IdCard.height = Vision.CmToPix(5.398);
			
			//~ localStorage.setItem("PREF_SS", Cali.ScreenSize);
		//~ }
		
		//~ const INST = new PIXI.Text("Push SPACEBAR to finish.",{fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'center', fontWeight: 'bold'});
		//~ INST.x = curApp.screen.width/2 - INST.width/2;
		//~ INST.y = curApp.screen.height - INST.height * 3;
		//~ curApp.stage.addChild(INST);
		
		//~ const KeyInstW = 150;
		//~ const KeyInstH = 100;
		//~ const KeyInst = new PIXI.Sprite.from("/img/CalArrows.png");
		//~ KeyInst.x = curApp.screen.width - KeyInstW - 50;
		//~ KeyInst.y = curApp.screen.height - KeyInstH - 50;
		//~ KeyInst.width = KeyInstW;
		//~ KeyInst.height = KeyInstH;
		//~ curApp.stage.addChild(KeyInst);
		
		//~ StdIn.RequestDirection();
		
		//~ StdIn.OnUp(()=>{
			//~ Cali.ScreenSize = Number(Cali.ScreenSize) + 5;
			//~ PrintDist();
		//~ });
		//~ StdIn.OnDown(()=>{
			//~ Cali.ScreenSize = Math.max(Number(Cali.ScreenSize) - 5, 5);
			//~ PrintDist();
		//~ });
		//~ StdIn.OnRight(()=>{
			//~ Cali.ScreenSize = Number(Cali.ScreenSize) + 1;
			//~ PrintDist();
		//~ });
		//~ StdIn.OnLeft(()=>{
			//~ Cali.ScreenSize = Math.max(Number(Cali.ScreenSize) - 1, 5);
			//~ PrintDist();
		//~ });
		
		//~ StdIn.RequestAccept();
		//~ StdIn.OnAccept(()=>{
			//~ Meta.Return("pick");
		//~ });
	//~ },
//~ });

//~ const SDC_Engine = Meta.Combine(Vision, Keyboard, {
	//~ Start : function(meta){
		//~ meta.backgroundColor = 0x000000;
		//~ const Vision = this._Vision;
		//~ const curApp = meta.PixiApp;
		//~ const StdIn = this.StdIn;

		//~ const goldmann = Vision.CmToPix(Vision.ArcToCm(1800)) / 2;
		
		//~ const focus = new PIXI.Graphics().beginFill(_FocusColor).drawCircle(0, 0, 10);
		//~ focus.x = curApp.screen.width/2;
		//~ focus.y = curApp.screen.height/2;
		//~ curApp.stage.addChild(focus);
		
		//~ const blindL = new PIXI.Graphics();
		//~ blindL.x = focus.x - Vision.CmToPix(Vision.ArcToCm(13.5*3600));
		//~ blindL.y = focus.y;
		//~ curApp.stage.addChild(blindL);
		
		//~ const blindR = new PIXI.Graphics();
		//~ blindR.x = focus.x + Vision.CmToPix(Vision.ArcToCm(13.5*3600));
		//~ blindR.y = focus.y;
		//~ curApp.stage.addChild(blindR);
		
		//~ const KeyInstW = 150;
		//~ const KeyInstH = 100;
		//~ const KeyInst = new PIXI.Sprite.from("/img/CalArrows.png");
		//~ KeyInst.x = curApp.screen.width - KeyInstW - 50;
		//~ KeyInst.y = curApp.screen.height - KeyInstH - 50;
		//~ KeyInst.width = KeyInstW;
		//~ KeyInst.height = KeyInstH;
		//~ curApp.stage.addChild(KeyInst);
		
		//~ var DRO = null;
		//~ PrintDist();
		
		//~ function PrintDist(){
			//~ Vision.PatientDistance = Cali.PatientDistance;
			
			//~ if (DRO != null){
				//~ DRO.parent.removeChild(DRO);
			//~ }
			//~ DRO = new PIXI.Text(C_PatientDistance + " CM",{fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'center', fontWeight: 'bold'});
			//~ DRO.x = curApp.screen.width/2 - DRO.width/2;
			//~ DRO.y = focus.y + DRO.height*5;
			//~ curApp.stage.addChild(DRO);
			
			//~ blindL.clear();
			//~ blindL.beginFill(_BlindColor).drawCircle(0, 0, Vision.BlindRad());
			//~ blindR.clear();
			//~ blindR.beginFill(_BlindColor).drawCircle(0, 0, Vision.BlindRad());
			
			//~ blindL.x = curApp.screen.width/2 - Vision.CmToPix(Vision.ArcToCm(15*3600));
			//~ blindR.x = curApp.screen.width/2 + Vision.CmToPix(Vision.ArcToCm(15*3600));
			
			//~ localStorage.setItem("PREF_PD", Cali.PatientDistance);
		//~ }
		
		//~ const INST = new PIXI.Text("Push SPACEBAR to finish.",{fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'center', fontWeight: 'bold'});
		//~ INST.x = curApp.screen.width/2 - INST.width/2;
		//~ INST.y = DRO.y + DRO.height + 20;
		//~ curApp.stage.addChild(INST);
		
		//~ StdIn.RequestDirection();
		
		//~ StdIn.OnUp(()=>{
			//~ Cali.PatientDistance = Number(Cali.PatientDistance) + 5;
			//~ PrintDist();
		//~ });
		//~ StdIn.OnDown(()=>{
			//~ Cali.PatientDistance = Math.max(Number(Cali.PatientDistance) - 5, 5);
			//~ PrintDist();
		//~ });
		//~ StdIn.OnRight(()=>{
			//~ Cali.PatientDistance = Number(Cali.PatientDistance) + 1;
			//~ PrintDist();
		//~ });
		//~ StdIn.OnLeft(()=>{
			//~ Cali.PatientDistance = Math.max(Number(Cali.PatientDistance) - 1, 5);
			//~ PrintDist();
		//~ });
		
		//~ StdIn.RequestAccept();
		//~ StdIn.OnAccept(()=>{
			//~ Meta.Return();
		//~ });
	//~ },
//~ });

//~ const SDC_Engine_2 = Meta.Engine(LegacyEmbed, {
	//~ LegacyEngine : {
		//~ Start : function(meta){
			//~ meta.backgroundColor = 0x000000;
			//~ const curApp = meta.PixiApp;
			
			//~ var Temp_PatientDistance = 50;
			//~ var Temp_DotRad = 1;
			//~ const goldmann = MetaVision.CmToPix(MetaVision.ArcToCm(1800)) / 2;
			
			//~ const focus = new PIXI.Graphics().beginFill(Cali.FocusColor).drawCircle(0, 0, 10);
			//~ this.focus = focus;
			//~ curApp.stage.addChild(focus);
			
			//~ const blindL1 = new PIXI.Graphics();
			//~ this.blindL1 = blindL1;
			//~ curApp.stage.addChild(blindL1);
			//~ const blindL2 = new PIXI.Graphics();
			//~ this.blindL2 = blindL2;
			//~ curApp.stage.addChild(blindL2);
			//~ const blindL3 = new PIXI.Graphics();
			//~ this.blindL3 = blindL3;
			//~ curApp.stage.addChild(blindL3);
			
			//~ const blindR1 = new PIXI.Graphics();
			//~ this.blindR1 = blindR1;
			//~ curApp.stage.addChild(blindR1);
			//~ const blindR2 = new PIXI.Graphics();
			//~ this.blindR2 = blindR2;
			//~ curApp.stage.addChild(blindR2);
			//~ const blindR3 = new PIXI.Graphics();
			//~ this.blindR3 = blindR3;
			//~ curApp.stage.addChild(blindR3);
			
			//~ var DRO = null;
			//~ var SRO = null;
			
			//~ const INST = new PIXI.Text("Push SPACEBAR to toggle rainbow.",{fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'center', fontWeight: 'bold'});
			//~ curApp.stage.addChild(INST);
			
			//~ this.PrintDist = ()=>{
				//~ MetaVision.PatientDistance = Temp_PatientDistance;
				
				//~ if (DRO != null){
					//~ DRO.parent.removeChild(DRO);
				//~ }
				//~ DRO = new PIXI.Text("Distance: " + Temp_PatientDistance + " CM (Left/Right to change)",{fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'center', fontWeight: 'bold'});
				//~ DRO.x = curApp.screen.width/2 - DRO.width/2;
				//~ DRO.y = focus.y + DRO.height*5;
				//~ curApp.stage.addChild(DRO);
				
				//~ if (SRO != null){
					//~ SRO.parent.removeChild(SRO);
				//~ }
				//~ SRO = new PIXI.Text("Blind Radius: " + Math.round(Temp_DotRad*10)/10 + " Deg (Up/Down to change)",{fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'center', fontWeight: 'bold'});
				//~ SRO.x = curApp.screen.width/2 - SRO.width/2;
				//~ SRO.y = focus.y + SRO.height*6;
				//~ curApp.stage.addChild(SRO);
				
				//~ blindL1.clear();
				//~ blindR1.clear();
				//~ blindL2.clear();
				//~ blindR2.clear();
				//~ blindL3.clear();
				//~ blindR3.clear();
				
				//~ blindL2.beginFill(0xff00ff).drawCircle(0, 0, MetaVision.CmToPix(MetaVision.ArcToCm(1*Temp_DotRad*3600)));
				//~ blindR2.beginFill(0xff00ff).drawCircle(0, 0, MetaVision.CmToPix(MetaVision.ArcToCm(1*Temp_DotRad*3600)));
				
				//~ if (Rainbow){
					//~ blindL1.beginFill(0x0000ff).drawCircle(0, 0, MetaVision.CmToPix(MetaVision.ArcToCm(1.8*Temp_DotRad*3600)));
					//~ blindR1.beginFill(0x0000ff).drawCircle(0, 0, MetaVision.CmToPix(MetaVision.ArcToCm(1.8*Temp_DotRad*3600)));
					//~ blindL3.beginFill(0xff0000).drawCircle(0, 0, MetaVision.CmToPix(MetaVision.ArcToCm(0.4*Temp_DotRad*3600)));
					//~ blindR3.beginFill(0xff0000).drawCircle(0, 0, MetaVision.CmToPix(MetaVision.ArcToCm(0.4*Temp_DotRad*3600)));
				//~ }
				
				//~ blindL1.x = curApp.screen.width/2 - MetaVision.CmToPix(MetaVision.ArcToCm(15*3600));
				//~ blindR1.x = curApp.screen.width/2 + MetaVision.CmToPix(MetaVision.ArcToCm(15*3600));
				//~ blindL2.x = curApp.screen.width/2 - MetaVision.CmToPix(MetaVision.ArcToCm(15*3600));
				//~ blindR2.x = curApp.screen.width/2 + MetaVision.CmToPix(MetaVision.ArcToCm(15*3600));
				//~ blindL3.x = curApp.screen.width/2 - MetaVision.CmToPix(MetaVision.ArcToCm(15*3600));
				//~ blindR3.x = curApp.screen.width/2 + MetaVision.CmToPix(MetaVision.ArcToCm(15*3600));
				
				//~ const Y = this.focus.y + MetaVision.CmToPix(MetaVision.ArcToCm(1.5*3600));
				//~ this.blindL1.y = Y;
				//~ this.blindR1.y = Y;
				//~ this.blindL2.y = Y;
				//~ this.blindR2.y = Y;
				//~ this.blindL3.y = Y;
				//~ this.blindR3.y = Y;
				
				//~ INST.x = curApp.screen.width/2 - INST.width/2;
				//~ INST.y = DRO.y + DRO.height + 100;
			//~ }
			//~ this.PrintDist();
			
			//~ Meta.KeyDown("Up", ()=>{
				//~ Temp_DotRad = Temp_DotRad + 0.1;
				//~ this.PrintDist();
			//~ });
			//~ Meta.KeyDown("Down", ()=>{
				//~ Temp_DotRad = Math.max(Temp_DotRad - 0.1, 0.1);
				//~ this.PrintDist();
			//~ });
			//~ Meta.KeyDown("Right", ()=>{
				//~ Temp_PatientDistance = Number(Temp_PatientDistance) + 5;
				//~ this.PrintDist();
			//~ });
			//~ Meta.KeyDown("Left", ()=>{
				//~ Temp_PatientDistance = Math.max(Number(Temp_PatientDistance) - 5, 5);
				//~ this.PrintDist();
			//~ });
			
			//~ var Rainbow = false;
			//~ Meta.KeyDown("Accept", ()=>{
				//~ Rainbow = !Rainbow;
				//~ this.PrintDist();
			//~ });
		//~ },
		//~ Resize : function(meta){
			//~ this.focus.x = meta.PixiApp.screen.width/2;
			//~ this.focus.y = meta.PixiApp.screen.height/2;
			
			//~ this.PrintDist();
		//~ },
	//~ },
//~ });

//~ const CC_Engine = Meta.Combine(Vision, Keyboard, {
	//~ Start : function(meta){
		//~ const curApp = meta.PixiApp;
		//~ const StdIn = this.StdIn;
		
		//~ this.SliderWidth = 500;
		//~ this.LeftColorSlider = new PIXI.Graphics().lineStyle(2, 0xcccccc).beginFill(0x000000).drawRect(0,0,this.SliderWidth,10);
		//~ this.LeftColorSlider.interactive = true;
		//~ this.LiveLeftSlider = false;
		//~ this.LeftColorSlider.on('pointerdown', ()=>{
			//~ this.LiveLeftSlider = true;
		//~ });
		//~ curApp.stage.addChild(this.LeftColorSlider);
		
		//~ this.LeftColorSliderFillBar = new PIXI.Graphics().beginFill(0xcccccc).drawRect(0,0,this.SliderWidth,10);
		//~ this.LeftColor = PIXI.utils.hex2rgb(C_LeftColor)[0];
		//~ curApp.stage.addChild(this.LeftColorSliderFillBar);
		
		//~ this.LeftPreview = new PIXI.Graphics();
		//~ curApp.stage.addChild(this.LeftPreview);
		
		//~ this.RightColorSlider = new PIXI.Graphics().lineStyle(2, 0xcccccc).beginFill(0x000000).drawRect(0,0,this.SliderWidth,10);
		//~ this.RightColorSlider.interactive = true;
		//~ this.LiveRightSlider = false;
		//~ this.RightColorSlider.on('pointerdown', ()=>{
			//~ this.LiveRightSlider = true;
		//~ });
		//~ curApp.stage.addChild(this.RightColorSlider);
		
		//~ this.RightColorSliderFillBar = new PIXI.Graphics().beginFill(0xcccccc).drawRect(0,0,this.SliderWidth,10);
		//~ this.RightColor = PIXI.utils.hex2rgb(C_RightColor)[2];
		//~ curApp.stage.addChild(this.RightColorSliderFillBar);
		
		//~ this.RightPreview = new PIXI.Graphics();
		//~ curApp.stage.addChild(this.RightPreview);
		
		//~ this.LeftSliderCircle = new PIXI.Graphics().lineStyle(2, 0x000000).beginFill(0x999999).drawCircle(0,0,10);
		//~ curApp.stage.addChild(this.LeftSliderCircle);
		//~ this.LeftSliderCircle.interactive = true;
		//~ this.LeftSliderCircle.on('pointerdown', ()=>{
			//~ this.LiveLeftSlider = true;
		//~ });
		
		//~ this.RightSliderCircle = new PIXI.Graphics().lineStyle(2, 0x000000).beginFill(0x999999).drawCircle(0,0,10);
		//~ curApp.stage.addChild(this.RightSliderCircle);
		//~ this.RightSliderCircle.interactive = true;
		//~ this.RightSliderCircle.on('pointerdown', ()=>{
			//~ this.LiveRightSlider = true;
		//~ });
		
		//~ this.PointUp = ()=>{
			//~ this.LiveLeftSlider = false;
			//~ this.LiveRightSlider = false;
			//~ localStorage.setItem("PREF_RED", this.LeftColor);
			//~ localStorage.setItem("PREF_BLUE", this.RightColor);
		//~ };
		
		//~ this.INST = new PIXI.Text("Push SPACEBAR to finish.",{fontFamily : 'Arial', fontSize: 24, fill : 0xffffff, align : 'center', fontWeight: 'bold'});
		//~ curApp.stage.addChild(this.INST);
		
		//~ StdIn.RequestAccept();
		//~ StdIn.OnAccept(()=>{
			//~ Meta.Return();
		//~ });
	//~ },
	//~ Tick : function(meta){
		//~ if(this.LiveLeftSlider == true){
			//~ const newLeft = Math.min(Math.max((meta.PixiApp.renderer.plugins.interaction.mouse.global.x - this.LeftColorSlider.x)/this.SliderWidth, 0), 1);
			//~ this.LeftColor = newLeft;
			//~ this.UpdatePreview(meta);
		//~ }
		
		//~ if(this.LiveRightSlider == true){
			//~ const newRight = Math.min(Math.max((meta.PixiApp.renderer.plugins.interaction.mouse.global.x - this.RightColorSlider.x)/this.SliderWidth, 0), 1);
			//~ this.RightColor = newRight;
			//~ this.UpdatePreview(meta);
		//~ }
	//~ },
	//~ Resize : function(meta){
		//~ this.LeftColorSlider.x = meta.Screen.width/3 - this.LeftColorSlider.width/2;
		//~ this.LeftColorSlider.y = 2 * meta.Screen.height/3;
		//~ this.LeftColorSliderFillBar.x = this.LeftColorSlider.x;
		//~ this.LeftColorSliderFillBar.y = this.LeftColorSlider.y;
		//~ this.LeftPreview.x = meta.Screen.width/3;
		//~ this.LeftPreview.y = meta.Screen.height/2;
		
		//~ this.RightColorSlider.x = 2*meta.Screen.width/3 - this.RightColorSlider.width/2;
		//~ this.RightColorSlider.y = 2 * meta.Screen.height/3;
		//~ this.RightColorSliderFillBar.x = this.RightColorSlider.x;
		//~ this.RightColorSliderFillBar.y = this.RightColorSlider.y;
		//~ this.RightPreview.x = 2*meta.Screen.width/3;
		//~ this.RightPreview.y = meta.Screen.height/2;
		
		//~ this.LeftSliderCircle.y = this.LeftColorSlider.y + this.LeftColorSlider.height/2;
		//~ this.RightSliderCircle.y = this.RightColorSlider.y + this.RightColorSlider.height/2;
		
		//~ this.INST.x = meta.PixiApp.screen.width/2 - this.INST.width/2;
		//~ this.INST.y = meta.PixiApp.screen.height - this.INST.height * 3;
		
		//~ this.UpdatePreview(meta);
	//~ },
	//~ UpdatePreview : function(meta){
		//~ this.LeftColorSliderFillBar.width = this.SliderWidth * this.LeftColor;
		//~ this.RightColorSliderFillBar.width = this.SliderWidth * this.RightColor;
		//~ this.LeftSliderCircle.x = this.LeftColorSliderFillBar.x + this.LeftColorSliderFillBar.width;
		//~ this.RightSliderCircle.x = this.RightColorSliderFillBar.x + this.RightColorSliderFillBar.width;
		
		//~ this.LeftPreview.clear();
		//~ C_LeftColor = PIXI.utils.rgb2hex([this.LeftColor, 0, 0]);
		//~ this.LeftPreview.beginFill(C_LeftColor).drawCircle(0,0,50);
		//~ this.RightPreview.clear();
		//~ C_RightColor = PIXI.utils.rgb2hex([0, 0, this.RightColor]);
		//~ this.RightPreview.beginFill(C_RightColor).drawCircle(0,0,50);
	//~ },
//~ });