import { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from "@mui/material";

function useLayoutChange() {
    const [layoutSize, setLayoutSize] = useState();
    const xxl = useMediaQuery("(min-width: 1536px) and (min-height: 680px)");
    const xxlNarrow = useMediaQuery("((min-width: 1536px) and (min-height: 400px))");
    const xl = useMediaQuery("((min-width: 1280px) and (min-height: 680px))");
    const lg = useMediaQuery("((min-width: 1024px) and (min-height: 680px)) or ((min-width: 1280px) and (min-height: 400px))");
    const md = useMediaQuery("((min-width: 768px) and (min-height: 680px)) or ((min-width: 1024px) and (min-height: 400px))");

  useEffect(() => {
    if (xxl) setLayoutSize("2xl");
    else if (xxlNarrow) setLayoutSize("2xlNarrow")
    else if (xl) setLayoutSize("xl")
    else if (lg) setLayoutSize("lg")
    else if (md) setLayoutSize("md")
    else setLayoutSize("md");
  }, [xxl, xxlNarrow, xl, lg, md])

  return layoutSize;
}

export default useLayoutChange
