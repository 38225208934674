import React from "react";
import Disclaimer from "../../scenes/Disclaimer";
import Navigation from "../navigations/Navigation";

function Step2({ handleNext, handlePrev }) {
  return (
    <>
      <Disclaimer handleNext={handleNext} />
    </>
  );
}

export default Step2;
