import React from "react";
import Welcome from "../../scenes/Welcome";
import { Button } from "@mui/material";

function Step0({ handleNext, handlePrev }) {
  return (
    <>
      <Welcome handleNext={handleNext} />
    </>
  );
}

export default Step0;
