import React from "react";
import { Button } from "rsuite";
import brightness from "../../Images/brightness_2.png";
import Navigation from "../navigations/Navigation";
import basicInfo from "../../Images/bgcalib.png";
import "../../App.css";
function Step8({ handleNext, handlePrev }) {
  return (
    <div className="w-full  my-[10vh]  max-w-[90rem]  h-[80vh]">
      <div className="absolute bottom-0 left-0 -top-[600px] right-0 z-20">
        <img className="w-full h-full" src={basicInfo} />
      </div>
      <div className="flex w-full  justify-between   h-full  flex-col">
        <div className="flex items-center w-full     gap-20 mx-auto my-auto 2xl:pt-0">
          <div className="relative z-40 flex flex-col   w-[50%] -mt-12 lg:pt-0 place-content-center">
            <p className="text-[#7C6FF7] xl:text-center text-[30px] xl:text-[40px] font-[800] xl:leading-[48px] font-Montserrat">
              100% Screen Brightness
            </p>
            <p className="xl:text-[32px] text-[25px] xl:pt-6 pt-2 xl:leading-[39px] font-Montserrat">
              Go into your system settings and turn your display brightness as
              high as possible.
            </p>
            <p className="xl:text-[32px] text-[25px] xl:pt-6 pt-2 xl:leading-[39px] font-Montserrat">
              {" "}
              <b>Win/PC : </b>Window Key + A
              <span className="block">
                <b>Mac : </b>Option + SHIFT + Keyboard Brightness
                <span className="block">Up</span>
              </span>
            </p>
          </div>

          <div className="absolute z-40 right-48">
            <img
              className="h-auto hidden md:block lg:w-[300px] w-[250px] xl:h-[318px] xl:w-[364px] aspect-auto"
              src={brightness}
            />
          </div>
        </div>
        <div className="relative z-40    flex justify-center w-full  uppercase font-Montserrat ">
          <Button
            style={{
              backgroundColor: "#7C6FF7",
              borderRadius: "25px",
              color: "white",
              fontSize: "24px",
              padding: "1.8rem",
              height: "3.5rem",
              fontWeight: "700",
              width: "518px",
            }}
            appearance="primary"
            className="button-style"
            onClick={handleNext}
          >
            I HAVE ADJUSTED MY SETTINGS
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Step8;
