import React from "react";
import covereye from "../../Images/cover_eye.png";
import homevvs from "../../Images/home-vvs.png";
import { Button, Stack, Grid } from "@mui/material";
import Logo from "../logo/Logo";
import { LaunchGame2 } from "../../scripts/GameEngines";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import TypographyTitle from "../TypographyTitle";
import ButtonNavigation from "../ButtonNavigation";
import CoverRightEye from "../../scenes/CoverRightEye";

function Step15({ handleNext, handlePrev, isTest, setVARNCBResponseR = () => {} }) {
  const handleVisualAcuity = () => {
    if (isTest) {
      setVARNCBResponseR({
        "Acuity": "32",
        "Warning": 32,
        "Hits": [
            5,
            0
        ]
      })
      handleNext();
    } else
    LaunchGame2("VisAcuityNear", (results) => {
      console.log(results);
      setVARNCBResponseR(results);
      handleNext();
    });
  };
  return (
    <CoverRightEye handleVisualAcuity={handleVisualAcuity} />
  );
}

export default Step15;
