import { Button, Grid, Stack } from "@mui/material";
import React from "react";
import image18 from "../../Images/image18.png";
import placeh2 from "../../Images/Distance-from-screen.png";
import Navigation from "../navigations/Navigation";
import tapemeasure from "../../Images/tapemeasurer.png";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import ButtonNavigation from "../ButtonNavigation";
import TypographyTitle from "../TypographyTitle";
import TypographyBody from "../TypographyBody";

function Step10({ handleNext, handlePrev, setStep, setUseMesure }) {
  return (
    <>
      {window.Cali.ScreenSize >= 10 ? (
        <MainLayout 
          bodyContent={
            <Stack spacing="20px">
              <TypographyTitle>
                Adjust your face distance to the screen.
              </TypographyTitle>
              <TypographyBody>
                {" "}
                IMPORTANT: Follow the directions carefully for accurate results.
              </TypographyBody>
              <div className="pb-4 mx-auto">
                <img src={placeh2} className="max-h-[250px] mx-auto aspect-auto" />
              </div>
            </Stack>
          }
          footerContent={
            <Grid container spacing={{md: "20px", lg: "100px"}}>
              <Grid item >

                <ButtonNavigation width="450px" onClick={(event) => {
                    handleNext();
                  }}>
                  HARDWARE-FREE INSTRUCTIONS
                </ButtonNavigation>
              </Grid>
              <Grid item >
                <ButtonNavigation width="450px" onClick={() => {
                    setStep(12);
                    setUseMesure(true);
                  }}>
                  I’LL USE A MEASURING DEVICE
                </ButtonNavigation>
              </Grid>
            </Grid>
          }
        />
      ) : (
        <MainLayout 
          bodyContent={
            <Stack spacing="20px">

              <TypographyBody>
                You have indicated that you are using a screen with a size below 10
                inches. <br />
                In order to get accurate results, please get a measuring device (ie
                a tape measure) ready for the eye tests.
              </TypographyBody>
              <div className="pb-4 mx-auto">
                <img src={image18} className="max-h-[270px] mx-auto aspect-auto" />
              </div>
              {/* <ul className="non-ul">
                <li className="text-[24px] text-center">Video Instructions</li>
              </ul> */}
            </Stack>
          }
          footerContent={
            <Grid container spacing={{md: "20px", lg: "100px"}}>
              <Grid item >
                <ButtonNavigation width="400px" onClick={handlePrev}>
                  Save and Logout
                </ButtonNavigation>
              </Grid>
              <Grid item >
                <ButtonNavigation width="400px" onClick={handleNext}>
                  I HAVE A MEASURING DEVICE
                </ButtonNavigation>
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
}

export default Step10;
