import { Button } from "@mui/material";
import { buttonSX, headerTextSX } from "../utils/constants";
import useLayoutChange from "../hooks/useLayoutChange";



const ButtonNavigation = (props) => { 

    const theSize = useLayoutChange();

    let theSX = theSize ? buttonSX[theSize] : {};
    if (props.width) theSX.width = props.width;
    else theSX.width = "177px";

    return (
        <Button
            {...props}
            className="button-style"
            style={{
                backgroundColor: props.disabled ? "#a6acaf" : "#7C6FF7",
                borderRadius: "25px",
                color: "white",
            }}

            sx={theSX}
        >
        {props.children}
        </Button>
  )};

export default ButtonNavigation;