import React from 'react'
import Navigation from '../navigations/Navigation'
import { LaunchDistance2 } from '../../scripts/GameEngines'

function IntermediateStep({ handleNext, handlePrev, setShowIntermediate }) {
  return (
    <>
      <div className='div flex justify-center items-center w-full h-full'>
        <Navigation
          handleNext={() => {
            LaunchDistance2(() => setShowIntermediate(false))
            setTimeout(handleNext, 500)
          }}
          handlePrev={() => setShowIntermediate(false)}
          nextText='Recalibrate Distance'
          prevText='Cancel'
        />
      </div>
    </>
  )
}

export default IntermediateStep
