import React, {useEffect} from "react";
import { Input, Button, Checkbox, Grid, Stack, TextField } from "@mui/material";
import { bodyTextSX, MedBackground } from "../../utils/constants";
import Navigation from "../navigations/Navigation";
import { useState } from "react";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import ButtonNavigation from "../ButtonNavigation";
import TypographyTitle from "../TypographyTitle";
import TypographyBody from "../TypographyBody";
import useLayoutChange from "../../hooks/useLayoutChange";

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { borderRadius } from "@mui/system";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(145, 145, 145, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: "15px",
    border: '1px solid #dadde9',
  },
}));

function Step6({ handleNext, handlePrev, setConditions, setAdditionalData, selectedConditions, currentAdditionalData, isTest }) {
  
  const theSize = useLayoutChange();
  let conditionSX = {py: "5px"};
  if (theSize == "2xl") conditionSX = {py: "10px"};
  else if (theSize == "xl") conditionSX = {py: "5px"};

  useEffect(() => {
    if(isTest) {
      setAdditionalData("This is some additional data in a note just for information.");
      setConditions(['Loss of Side Vision', 'Sandy / Gritty Feeling','Foreign Body Sensation']);
    }
  }, []);

  return (
    <MainLayout 
      bodyContent={
        <Stack sx={{width: "100%"}} spacing="12px" justifyContent="space-between">
            <TypographyTitle>
                Do you experience any of the following vision problems?
            </TypographyTitle>
            <TypographyBody>
              Hover over any item for more details. Check the boxes below.
            </TypographyBody>
            <div className="grid grid-cols-3 w-[100%] mt-[12px] text-[24px] pb-2">
              {MedBackground.map((obj, index) => {
                // Determine the background color class based on the column index
                const bgColorClass = index % 2 === 0 ? "bg-grey" : "bg-blue";
                return (
                  <div key={index}>
                    {obj.conditions.map((condition, conditionIndex) => (

                      <div className="flex items-center" key={conditionIndex}>
                        <HtmlTooltip title={
                          <TypographyBody isSmall> {obj.toolTips[conditionIndex]} </TypographyBody>
                          } placement="right">

                        <Checkbox
                          key={condition}
                          id={`checkbox-${condition}`}
                          value={condition}
                          color="primary"
                          sx={conditionSX}
                          checked={selectedConditions && selectedConditions.includes(condition)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setConditions((conditions) => {
                                if (!conditions.includes(condition))
                                  return [...conditions,condition]
                                else return conditions;
                            });
                            } else {
                              setConditions((conditions) => {
                                const output = conditions.filter((c) => c !== condition);
                                return output;
                            });
                            }
                          }}
                        ></Checkbox>
                        {/* <label
                          htmlFor={`checkbox-${condition}`}
                          className="text-[18px] cursor-pointer font-Montserrat"
                        >
                          {" "}
                          {condition}
                        </label> */}
                          <TypographyBody component="label"
                            htmlFor={`checkbox-${condition}`}
                            className="cursor-pointer"
                            isSmall
                          >
                            {" "}
                            {condition}
                          </TypographyBody>
                          </HtmlTooltip>

                      </div>

                    ))}
                  </div>
                );
              })}
            </div>
            <TextField
              placeholder="(Optional) Type out any additional details about your vision for the doctor."

              value={currentAdditionalData}
              onChange={(e) => setAdditionalData(e.target.value)}
            ></TextField>

 

        </Stack>

      }
      footerContent={
        <Stack direction="row" justifyContent="space-around" alignItems="center" spacing="200px" sx={{width: "100%"}}>
          <ButtonNavigation onClick={handlePrev}>
            BACK
          </ButtonNavigation>
          <ButtonNavigation onClick={handleNext}>
            NEXT
          </ButtonNavigation>
        </Stack>
      }
    
    />
  );
}

export default Step6;
