import React, { useEffect } from "react";
import "rsuite/dist/rsuite.min.css";
import Steps from "../components/steps/Steps";

export default function FullScene() {
  useEffect(() => {
    window.Meta.Window(document.getElementById("MetaEngine"));
  }, []);

  return (
    <div className="w-[100%] h-[100%]">
      <Steps />
    </div>
  );
}
