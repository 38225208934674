import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { GlassProps } from "../../utils/constants";
import Logo from "../logo/Logo";
import { format } from "date-fns";
import DryEyeSurvey2 from "../steps/DryEyeSurvey2";

const GeneralVisionReport = React.forwardRef(
  (
    {
      formDataVision,
      conditions,
      contactFormData,
      CBResponse,
      wearGlasses,
      tableDataContacts,
      tableData,
      CBResponseR,
      additionalData,
      VALNCBResponse,
      VARNCBResponseR,
      VALFCBResponse,
      VARFCBResponseR,
      distance,
      dryEye1,
      dryEye2
    },
    ref
  ) => {
    const MyText = ({ children }) => (
      <Typography fontSize={"9pt"}>{children}</Typography>
    );
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyy-MM-dd");
const answerValues = {
  'Never': 0,
  'Not Very Often': 1,
  'Sometimes': 2,
  'Often': 3,
  'Always': 4,
  '-': 0 // Assuming '-' or other answers are counted as 0
};

// Function to calculate the sum of answers
function calculateAnswerSum(data) {
  return data?.reduce((sum, item) => {
    const value = answerValues[item.answer] || 0; // Default to 0 if answer not found
    return sum + value;
  }, 0);
}

function getAcuity(input) {
  let output = null;
  if (!input || !input?.Acuity ||  (input && input.Acuity && input.Acuity.includes("??") )) 
    output = "No Result";
  else output = input.Acuity;

  return output;
}

const VALNCBResponseAcuity = getAcuity(VALNCBResponse); 
const VARNCBResponseRAcuity = getAcuity(VARNCBResponseR); 
const VALFCBResponseAcuity = getAcuity(VALFCBResponse); 
const VARFCBResponseRAcuity = getAcuity(VARFCBResponseR); 

    return (
      <Paper
        elevation={ref ? 2 : 0}
        id="pdfReport"
        ref={ref}
        sx={{
          m: "auto",
          borderRadius: 0,
          mt: 1,
          maxWidth: "210mm",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          py: 4,
          px: 4,
          "@media print": {
            minHeight: "250mm", //297mm standard A4size
            width: "210mm",
            pageBreakAfter: "always",
            border: "none",
            boxShadow: "none",
          },
        }}
      >
        <Logo width="230px" height="110px"/>
        <Typography
          variant="title"
          component={"h3"}
          textAlign={"center"}
          fontSize={"14pt"}
          m={0}
        >
          You may share this report with your eye care professional.
        </Typography>
      
        {/* <Typography
          textAlign={"center"}
          fontSize={"13pt"}
          className="underline"
        >
          Click on the higlighted text for more information
        </Typography> */}
        <Grid container>
          <Grid item xs={6} sx={{ fontSize: "8pt" }}>
            <p className="font-bold text-[12px]">Date created: {formattedDate}</p>
            <p className="font-bold text-[12px]">
              {formDataVision?.firstName} | {formDataVision?.lastName} |{" "}
              {contactFormData?.email} | {formDataVision?.birthYear} |{" "}
              {formDataVision?.birthSex}
              </p>
              <p className="font-bold text-[12px]">Screener Confidence: 90%</p>
              <p className=" text-[12px]"><span className="font-bold">Last Eye exam: </span>{formDataVision?.lastEyeExam}</p>
              <p className=" text-[12px]"><span className="font-bold"> Vision problems: </span> {conditions?.length ? conditions.join(", ") : "None"}</p>
              <p className=" text-[12px]"><span className="font-bold">Additional notes: </span>{additionalData}</p>
              <p className=" text-[12px]"><span className="font-bold"> Vision Acuity Near (40 cm): </span> R-20/{VALNCBResponseAcuity} L-20/{VARNCBResponseRAcuity}</p>
              <p className=" text-[12px]"><span className="font-bold"> Vision Acuity Intermediate ({distance} cm): </span>  R-20/{VALFCBResponseAcuity} L-20/{VARFCBResponseRAcuity}</p>
              <p className=" text-[12px]"><span className="font-bold"> Eye Strain (CISS): </span>{(calculateAnswerSum(dryEye1))}</p>
              <p className=" text-[12px]"><span className="font-bold">Dry Eye  Occurrence(s): </span></p>
              <p><div>
      {dryEye2?.map(({ symptom, answer }, index) => (
        <p key={index} className="text-[12px]">
          <span className="">{symptom} Occurrence(s): </span>
          {answer.split(",")[0]}
        </p>
      ))}
    </div></p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
          <p className="font-bold text-[12px]"> Amsler Grid Images </p>
            <div className="flex gap-2">
             
              <div className="h-[170px] w-[170px]">
                <Typography pl={1} fontSize={"10pt"}>
                  {" "}
                  Right(OD){" "}
                </Typography>
                <img
                  src={`data:image/png;base64${CBResponse?.IMG}`}
                  alt="test"
                />

              </div>
              <div className="h-[170px] w-[170px]">
                <Typography pl={1} fontSize={"10pt"}>
                  {" "}
                  Left(OS){" "}
                </Typography>
                <img
                  src={`data:image/png;base64${CBResponseR?.IMG}`}
                  alt="test"
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sx={{ fontSize: "8pt" }}></Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={12}>
            <Typography fontSize={"10pt"}>
              Current Lens Prescription:
            </Typography>
            { wearGlasses === "no" &&
                <div className="flex-1">None</div> 
            }
            { wearGlasses === "yes" &&
              <>
            <div className="flex gap-2 text-[9.5pt]">
              Glasses:
              <div className="flex flex-1 gap-2 display-inline">
                {wearGlasses === "no" ? (
                  <div className="flex-1">None</div>
                ) : (
                  tableData?.map((eye, index) => (
                    <div className="flex-1" key={index + 10}>
                      {index === 0 ? "Right " : " Left  "}
                      {eye
                        .map(
                          (field, _index) => `${GlassProps[_index]}: ${field}`
                        )
                        .join(", ")}
                    </div>
                  ))
                )}
              </div>
            </div>
            <div className="flex gap-2 text-[9.5pt]">
              Contacts:
              <div className="flex flex-1 gap-2 display-inline">
                {wearGlasses === "no" ? (
                  <div className="flex-1">None</div>
                ) : (
                  tableDataContacts?.map((eye, index) => (
                    <div className="flex-1" key={index + 5}>
                      {index === 0 ? "Right " : " Left  "}
                      {eye
                        .map(
                          (field, _index) => `${GlassProps[_index]}: ${field}`
                        )
                        .join(", ")}
                    </div>
                  ))
                )}
              </div>
            </div>
            </>
            }
          </Grid>
        </Grid>
        <Grid container sx={{paddingTop: 4}}>
          <Grid item xs={12}>
          <Typography fontSize={"8pt"} textAlign={"justify"}>
          The <u>Virtual Vision Screener</u> is <b>not a medical evaluation </b>
          and shouldn't replace consultations with qualified eye specialists.
          It's not designed for disease diagnosis or its management, treatment,
          or prevention. This assessment aims to provide a basic understanding
          of your visual health and to suggest if a detailed eye check-up is
          needed.Your eyes should be examined by an eye care professional every
          two years or earlier if you observe any vision alterations. Vision
          Science Labs is not responsible for any harm or outcomes stemming from
          the use of the Vision Assessment or the information it offers.
        </Typography>
            <Typography
              fontSize={"10pt"}
              fontWeight={"bold"}
              textAlign={"justify"}
            >
              DISCLAIMER: These tests and results are not medical advice and
              have no diagnostic value. A complete eye examination must be
              carried out by an eye care professional to detect any adverse
              visual problems.
            </Typography>
          </Grid>
        </Grid>
        <Typography my={1} fontSize={"10pt"} textAlign={"center"}>
          &copy; {new Date().getFullYear()} Vision Science Labs, Inc. All rights
          reserved.
        </Typography>
      </Paper>
    );
  }
);

export default GeneralVisionReport;
