import React, { useState, useEffect } from "react";
import { Button, Grid, Stack } from "@mui/material";
import FixedHeading from "../FixedHeading";
import MainLayout from "../layout/MainLayout";
import ButtonNavigation from "../ButtonNavigation";
import TypographyTitle from "../TypographyTitle";
import TypographyBody from "../TypographyBody";

function DryEyeSurvey2({ handleNext, handlePrev, setSurveyAnswers, surveyAnswers, isTest }) {
  const [symptomsSeverity, setSymptoms] = useState([
    {
      symptom: "Dryness, Grittiness or Scratchiness",
      options: ["Never", "Never", "None"], // Initialize with default options
    },
    {
      symptom: "Soreness or Irritation",
      options: ["Never", "Never", "None"], // Initialize with default options
    },
    {
      symptom: "Burning or Watering",
      options: ["Never", "Never", "None"], // Initialize with default options
    },
    {
      symptom: "Eye Fatigue",
      options: ["Never", "Never", "None"], // Initialize with default options
    },
  ]);

  useEffect(() => {
    if (isTest) {
      const sampleData = [
          {
              "symptom": "Dryness, Grittiness or Scratchiness",
              "options": [
                  "Never",
                  "Never",
                  "Tolerable"
              ],
              "answer": "Never, Never, Tolerable"
          },
          {
              "symptom": "Soreness or Irritation",
              "options": [
                  "Within Past 3 Months",
                  "Sometimes",
                  "None"
              ],
              "answer": "Within Past 3 Months, Sometimes, None"
          },
          {
              "symptom": "Burning or Watering",
              "options": [
                  "Today",
                  "Often",
                  "None"
              ],
              "answer": "Today, Often, None"
          },
          {
              "symptom": "Eye Fatigue",
              "options": [
                  "Within 72 Hours",
                  "Never",
                  "Uncomfortable"
              ],
              "answer": "Within 72 Hours, Never, Uncomfortable"
          }
      ]
      setSurveyAnswers(sampleData);
      setSymptoms(sampleData);

    }
  }, [])

  const setSurveyAnswersAns = (prevAnswers) => {
    setSurveyAnswers(() =>
      prevAnswers.map((ans, index) => {
        const selectedOptions = symptomsSeverity[index].options;
        return {
          ...ans,
          answer: selectedOptions.join(", "), // Combine selected options into a string
        };
      })
    );
    handleNext();
  };

  const handleOnChange = (symptomIndex, optionIndex, value) => {
    setSymptoms((prevSymptoms) =>
      prevSymptoms.map((symptom, index) =>
        index === symptomIndex
          ? {
              ...symptom,
              options: symptom.options.map((opt, i) =>
                i === optionIndex ? value : opt
              ),
            }
          : symptom
      )
    );
  };

  return (
    <MainLayout 
      bodyContent={
        <Stack spacing="12px" alignItems="flex-start" sx={{width: "100%", height: "100%"}}>
          <TypographyTitle>
              Dry Eye Survey
          </TypographyTitle>
          <TypographyBody>
            Tolerable – not perfect, but not uncomfortable <br />
            Uncomfortable – irritating, but does not interfere with my day <br />
            Bothersome – irritating and interferes with my day <br />
            Intolerable – unable to perform my daily tasks <br />
          </TypographyBody>
          <table className="h-[15rem] w-[100%] text-[18px] xl:text-[24px]">
            <thead className="bg-[#dddddd]">
              <tr>
                <th className="text-left pl-2 w-[35%]">Symptom</th>
                <th className="px-10 text-center ">When do they occur?</th>
                <th className="px-6 text-center ">Frequency</th>
                <th className="px-10 text-center ">Severity</th>
              </tr>
            </thead>
            <tbody>
              {symptomsSeverity.map(({ symptom, options }, symptomIndex) => {
                return (
                  <tr key={symptomIndex}>
                    <td className="pl-2">{symptom}</td>
                    {options.map((option, optionIndex) => {
                      // Define options for each column
                      let columnOptions = [];
                      if (optionIndex === 0) {
                        columnOptions = [
                          "Never",
                          "Today",
                          "Within 72 Hours",
                          "Within Past 3 Months",
                        ];
                      } else if (optionIndex === 1) {
                        columnOptions = ["Never", "Sometimes", "Often", "Constant"];
                      } else if (optionIndex === 2) {
                        columnOptions = [
                          "None",
                          "Tolerable",
                          "Uncomfortable",
                          "Bothersome",
                          "Intolerable",
                        ];
                      }

                      return (
                        <td className="text-center" key={optionIndex}>
                          <select
                            className="bg-transparent" // Transparent background
                            id={`${symptom}_${optionIndex}`} // Unique identifier
                            name={`${symptom}_${symptomIndex}`} // Unique name for each group
                            value={option} // Current selected value
                            onChange={(e) =>
                              handleOnChange(symptomIndex, optionIndex, e.target.value)
                            } // Update handler
                          >
                            {columnOptions.map((opt, idx) => (
                              <option key={idx} value={opt}>
                                {opt}
                              </option>
                            ))}
                          </select>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Stack>
      }
      footerContent={
        <ButtonNavigation width="150px" onClick={() => setSurveyAnswersAns(symptomsSeverity)}>
          NEXT
        </ButtonNavigation>
      }
    />

  );
}

export default DryEyeSurvey2;
